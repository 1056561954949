import get from 'lodash.get';
import React, { Fragment } from 'react';
import {
  OLDEST_ALLOWED_YEAR_BUSINESS,
  OLDEST_ALLOWED_YEAR_PERSON,
} from '../utils/validYearOfBirth';

function formatted(strings: TemplateStringsArray, ...args: React.ReactNode[]) {
  let children: React.ReactNode[] = [];
  strings.forEach((string, index) => {
    children = children.concat(string, args[index] ?? '');
  });
  return React.createElement(Fragment, {}, ...children);
}

const strings = {
  errors: {
    tokenLimitedValidation: {
      mustBeNumberOrString:
        'Must be a number greater than 0; use -1 for unlimited',
    },
    artBlocksProjectIdValidation: {
      mustBeNumber: 'Must be a number',
      mustBePositive: 'Must be greater than 0',
    },
    highlightIdValidation: {
      mustBeByte32:
        'Must be byte32, you can get the ID from the HighLight collection owner',
    },
    customizationFormValidation: {
      urlFormat: 'Must be in the format "https://example.com"',
      noLogo: 'To add a link, you must provide a logo',
    },
    metafields: {
      // The way these work is you can choose a custom message based on
      // the `metafield.reason`
      // E.g. if it's `object_type.required` then create an entry like this:
      //
      // object_type: {
      //   required: 'Please choose an object type',
      // },
      //
      // Otherwise, we'll fall back to a general error from the `general` field.

      object_type: {
        required: 'Please choose an object type',
      },
      medium: {
        required: 'Medium is required',
      },
      production_year: {
        required: 'Production year is required',
        invalid: 'Must be between 1000 and the current year',
      },
      image_url: {
        required: 'Please upload at least 2 images',
      },
      edition_type: {
        variants_cant_have_editions:
          'A product that has variants cannot have editions',
      },
      general: {
        variants_cant_have_editions:
          'Products with variants cannot be editions',
        not_a_number: 'Must be a number',
        bad_input: 'This value is invalid',
        required: 'Must enter a value',
        invalid_url: 'Must be a valid URL',
        input_too_large: 'Must be less than 5000 characters',
        zero_input: 'Must be greater than 0',
        wrong_trigger_level:
          'NFT settings were provided but this was marked for certification',
        not_found: 'This item was not found',
        relationship_not_authorized: 'Creator not verified',
        wrong_edition_type:
          'This product has been already issued with an incompatible edition type',
        wrong_contract_type: 'This contract is not the correct type',
        wallet_cant_mint_on_contract:
          "The minting wallet can't access this contract",
        wallet_cant_transfer_existing:
          "The minting wallet can't access this NFT",
        nft_already_sold: 'The NFT has already been sold',
        payment_splitter_old_version:
          'The payment splitter used is no longer supported. Please create a new payment splitter or contact support@verisart.com for assistance.',
        media_too_large: 'File is too large',
        media_type_not_supported: 'File type not supported',
        trait_reserved: 'One of the trait names you used is reserved',
      },
    },
    productForm: {
      toastFailure: "Product couldn't be updated",
    },
  },
  header: {
    tabs: {
      1: 'Home',
      2: 'NFTs',
      3: 'Creators',
      4: 'Settings',
      6: 'Contracts',
      7: 'Tokengates',
      8: 'Royalty splits',
      9: 'Certificates',
      10: 'Customizations',
      11: 'Prints',
      12: 'Reports',
    },
    errorMessage: "Sorry, we couldn't load the menu",
  },
  drops: {
    list: {
      title: 'Drops',
      action: 'Create',
    },
  },
  premintedNFTs: {
    metadata: {
      title: 'Title',
      description: 'Description',
      chain: 'Blockchain',
      contractType: 'Contract type',
      contractName: 'Contract name',
      contractAddress: 'Contract address',
      tokenId: 'Token ID',
      assetType: 'Asset type',
      asset: 'Asset',
      attributes: 'Attributes',
      displayImage: 'Display image',
      symbol: 'Contract symbol',
      tags: 'Tag',
      productionYear: 'Production year',
    },
    toasts: {
      success: 'NFT imported',
      fail: 'NFT not imported',
      addedToExistingDrop: 'Added to existing drop',
      newDropCreated: 'New drop created',
    },
    modal: {
      importTitle: 'Import NFT',
      previewTitle: 'Preview imported NFT',
      buttons: {
        import: 'Import',
        cancel: 'Cancel',
        continue: 'Continue',
      },
      cardTitle: 'Paste an OpenSea link to import your NFT',
      urlError:
        'URL should be in the format https://opensea.io/assets/chain/address/tokenId',
      urlHelpText: 'Ethereum or Polygon ERC-721',
    },
    errors: {
      bannerTitle: 'Sorry, we couldn’t preview your NFT',
      bannerTitleImport: 'Sorry, we couldn’t import your NFT',
      bannerSubtitle: 'Here’s some more detail about what went wrong:',
      noImage: "Missing image. The NFT doesn't appear to have an image",
      noMetadata: 'Missing metadata. It might not exist onchain yet.',
      nftNotFound: 'NFT not found. Check the details and try again.',
      errorCode: 'Sorry something went wrong. Here is an error code: ',
      otherError: 'Sorry something went wrong',
      contractNotSupported: 'Contract type not supported. Must be ERC-721.',
      duplicateError: 'This NFT already exists in your store.',
    },
  },
  activateMintingModal: {
    title: 'Get started now in Test Mode',
    description:
      "Shopify restricts access to NFT minting. You're currently in Test Mode. You can try out NFT minting, but you'll need to contact us when you're ready to go live.",
    firstCTA: 'Create your first NFT in Test Mode',
    secondCTA: 'Apply for full access to NFT minting',
    primaryAction: {
      text: 'Submit info',
      link: 'https://verisart.typeform.com/to/belDDrRv',
    },
  },
  info: {
    dropNeedsReview: 'Needs Review',
    testMode: 'Test Mode',
    activateMinting: {
      description: 'Shopify restricts NFT minting.',
      cta: 'Find out how to activate',
      newDescriptionPartOne:
        "You’re in Test Mode. To sell NFTs, complete Shopify's application process.",
      newCta: 'Learn more',
    },
    giftingModeWarning: {
      title: 'You’re in Gifting mode',
      description:
        'You can only create free NFTs. Update the price of this product before you go live.',
      link: 'Learn more',
    },
  },
  chainInformation: {
    title: 'Blockchain information',
    royalty: 'Royalty split',
    contract: 'Contract',
    chain: 'Blockchain',
  },
  footer: {
    infoText: 'Learn more about',
    companyTitle: 'Verisart',
    plan: '5% transaction fee / $0.25 minimum per NFT sale',
    wooPlan:
      'No transaction fees (promo valid until June 2024, after which a 5% fee with $0.25 minimum per NFT or print sale applies).',
  },
  blockchain: {
    ETHEREUM: 'Ethereum',
    ETHEREUM_SEPOLIA: 'Sepolia Testnet',
    FAKE_ETHEREUM_ON_SEPOLIA: 'Fake Ethereum (on Sepolia)',
    FAKE_BASE_ON_BASE_SEPOLIA: 'Fake Base (on Base-Sepolia)',
    POLYGON: 'Polygon',
    POLYGON_AMOY: 'Polygon Amoy Testnet',
    BASE_MAINNET: 'Base',
    BASE_SEPOLIA: 'Base Sepolia Testnet',
  },
  blockchainCurrency: {
    ETHEREUM: 'ETH',
    ETHEREUM_SEPOLIA: 'SepoliaETH',
    FAKE_ETHEREUM_ON_SEPOLIA: 'ETH',
    FAKE_BASE_ON_BASE_SEPOLIA: 'ETH',
    POLYGON: 'MATIC',
    POLYGON_AMOY: 'MATIC',
    BASE_MAINNET: 'ETH',
    BASE_SEPOLIA: 'BaseSepoliaETH',
  },
  banners: {
    linkedAccount: {
      title: 'Account linked to your store',
      description: (...args: string[]) =>
        `Verisart account for ${args[0]} linked to your store`,
    },
    upsellPaymentComplete: {
      title: (...args: Array<string>) =>
        `Your 14-day ${args[0]} trial is active`,
      contentPrefix: 'Check out our',
      contentLink: 'Quick Start guide',
      contentSuffix: 'and start certifying your products',
    },
    trialStatusBanner: {
      title: (...args: Array<string>) => `You're on a 14-day ${args[0]}.`,
      subtitle: 'Manage your plan',
    },
    verificationStatus: {
      approvalPendingTitle: 'Reviewing your details',
      approvalPending: (...args: Array<string>) =>
        `We're reviewing your account details. We'll email you at ${args[0]} when this is complete. We aim to reply within 2 business days.`,
      accountApprovedTitle: 'Account ready',
      accountApproved: () =>
        'Your account is ready. Choose products for certification.',
      representedArtistsPendingTitle: 'Pending authorization',
      representedArtistsPending: (...args: Array<string>) =>
        `We're waiting for authorization from ${args[0]} represented creator(s) linked to your account.`,
      addRelationshipTitle: 'Complete set up',
      addRelationship:
        'Complete your account setup by adding a creator and verifying your identity.',
      action: {
        creators: 'Add creator',
        products: 'Go to Products',
        website: 'Go to Verisart.com',
      },
    },
    trialBanner: {
      title: 'Your 14-day free trial expires soon',
      actionContent: 'Upgrade now',
    },
    quotaBanner: {
      title: 'You have exceeded your plan limits',
      content:
        "Don't worry, nothing will stop working. Please upgrade your plan.",
      actionContent: 'View usage',
    },
    nftBanner: {
      header: "You're in Test mode",
      body: "You can create test NFTs. To sell NFTs, complete Shopify's application process.",
      linkText: 'Apply',
      newLevelSellingHeader: 'You’re approved for Selling',
      newLevelSellingBody:
        'You can sell NFTs on your store using Shopify Payments, as long as you respect Shopify’s terms of service',
      newLevelGiftingHeader: 'You’re approved for Gifting',
      newLevelGiftingBody:
        'You cannot sell NFTs on your store, but you can give them away. As long as you don’t charge anything, Verisart will process your NFTs orders.',
    },
    legacyBanner: {
      business: {
        content:
          'Use your 14-day FREE trial to continue with your Pro plan (business)',
        title:
          'Your Business plan is now called Pro (business), please approve and start your 14-day FREE trial',
      },
      actionContent: 'Renew your plan',
      content: (...args: Array<string>) =>
        `Use your 14-day FREE trial to continue with your ${args[0]} plan,${
          args[0] === 'Basic' ? ' now with extended features,' : ''
        } or upgrade your plan.`,
      title: (...args: Array<string>) =>
        `Your ${args[0]} plan has new features, start your 14-day FREE trial`,
    },
    downloadGuide: {
      title: 'Check out our quick start guide',
      description: 'Find out how to optimize your shop for certification.',
      button: 'Read guide',
    },
    identityVerificationBanner: {
      title: 'Pending Identity Verification',
      description: 'Your creator identity has not been verified yet.',
      actionContent: 'View Status',
    },
  },
  externalLinks: {
    opensea: 'View on OpenSea',
    helper: 'Only available if at least 1 NFT has been minted',
  },
  onboarding: {
    shopifyPagination: {
      first: '1 of 3',
      second: '2 of 3',
      third: '3 of 3',
    },
    pagination: {
      first: '1 of 4',
      second: '2 of 4',
      third: '3 of 4',
      fourth: '4 of 4',
    },
    action: {
      next: 'Next',
    },
    creatorDetails: {
      title: 'Set up a creator',
      businessTitle: 'Your business details',
      creatorDescription:
        'Your creator name for your NFTs and Certificates of Authenticity.',
      link: 'Learn more',
      creatorFirstNameLabel: 'First name',
      brandName: 'Brand name',
      creatorLastNameLabel: 'Last name',
      creatorYear: {
        individual: 'Year of birth',
        business: 'Year founded',
      },
      validation: {
        business: {
          invalidYear: 'Year founded is invalid. Format should be YYYY.',
          maxYear: 'Year founded must not be in the future.',
          minYear: `Year founded must be greater than ${OLDEST_ALLOWED_YEAR_BUSINESS}.`,
        },
        individual: {
          invalidYear: 'Year of birth is invalid. Format should be YYYY.',
          maxYear: 'Year of birth must not be in the future.',
          minYear: `Year of birth must be greater than ${OLDEST_ALLOWED_YEAR_PERSON}.`,
        },
      },
    },
    newOrExisting: {
      title: 'Sign up',
      option: {
        new: "I'm new to Verisart",
        existing: 'I have an account on Verisart.com',
      },
      notice: "By proceeding, you agree to Verisart's terms and conditions.",
      readTerms: 'Read full T&Cs.',
    },
    verisartLogin: {
      action: {
        next: "Let's go",
      },
      title: 'I have a Verisart account',
      message: "We'll take you to Verisart.com where you can login.",
    },
    selectPlanType: {
      title: 'Select plan',
      notice: 'Change or cancel anytime from the plan page',
      payAsYouGo: {
        label: 'Pay as you sell',
        help: 'For occasional sales. No monthly fee.',
      },
      grow: {
        label: 'Growth',
        help: 'For a single creator. $29.99 /mo. Free 14-day trial.',
      },
      pro: {
        label: 'Pro',
        help: 'For high volume sales and/or representing creators. $99.99 /mo. Free 14-day trial.',
      },
    },
    selectPlanTier: {
      title: 'Choose a plan',
      individual: {
        intro: 'Choose an Individual plan with {0}. Not sure? {1}',
      },
      business: {
        intro: 'Choose a Business plan with {0}. Not sure? {1}',
      },
      trial: '14-day FREE trial',
      compare: 'Compare plans',
    },
    onboardingToast: 'Success! Onboarding completed',
  },
  components: {
    error: {
      title: 'Sorry, something went wrong',
      message: `There was a problem, please refresh the page and try
      again. If the problem persists, contact
      support@verisart.com.`,
      technicalDetails: 'Here is some technical details:',
    },
  },
  royaltySplitPage: {
    form: {
      title: (...args: Array<string>) => `${args[0]} royalty details`,
    },
    editPage: {
      title: 'Edit royalties',
      action: 'Save',
      actionLoading: 'Loading',
      banner: {
        success: {
          title: 'Success',
          message: 'You have successfully updated the royalty details',
        },
        fail: {
          title: 'Sorry, there was a problem',
          message:
            'Something went wrong on our end. Please contact customer support',
        },
      },
    },
    settingsCTA: {
      title: 'Manage royalties',
      body: 'View details of secondary royalties on NFTs.',
      buttonText: 'Manage royalties',
    },
    errors: {
      splitsAbove100:
        'The total percentages must add up to a value less than 100%',
      nameRequired: 'A name is required',
      blockchainRequired: 'Blockchain is required',
      atLeastOneRecipient: 'At least 1 recipient required',
    },
    chainSection: {
      title: 'Blockchain',
      selectInput: {
        label: 'Blockchain',
      },
    },
    confirmationModal: {
      title: 'Deploy royalty split',
      primaryAction: 'Continue',
      secondaryAction: 'Cancel',
      banner: {
        title: 'This blockchain action is permanent',
        firstLine:
          'Once the action completes, you can’t update the information',
        secondLine: 'On Ethereum, you need funds in your wallet',
        thirdLine: 'Other blockchains are free with your plan',
      },
    },
    labels: {
      name: {
        label: 'Name',
        helpText: 'Helps find your royalty records within this app',
      },
    },
    createPage: {
      title: 'Set royalties',
      buttonText: 'Save',
    },
    title: 'Royalties',
    actionText: 'Add royalty details',
    emptyState: {
      title: 'Set royalties',
      subtitle: 'Choose recipients for secondary sales royalties',
      ctaText: 'Add royalty details',
    },
    recipientsEmptyState: {
      title: 'Add Recipients',
      content: 'Add recipient for secondary sale royalties',
      ctaText: 'Add recipient',
    },
    deployedBanner: {
      title: 'Royalty details saved successfully',
      message: 'Your royalty details were registered on chain',
    },
    bestPractice: {
      title: 'Best practice',
      content:
        'NFT royalties are typically set between 8-10% on secondary sales.',
    },
    royaltySplitItem: {
      recipients: 'Recipients',
      totalRoyalties: 'Total royalties',
      noAddress: 'Pending',
    },
    generalError: {
      message:
        'Something went wrong on our end. Please contact customer support.',
      title: 'Something went wrong',
    },
    addRecipientModal: {
      creators: 'Authorized creators',
      representers: 'Other recipients',
      verifiedWallets: 'Verified',
      unverifiedWallets: 'Unverified',
      title: 'Add recipient',
      button: 'Save',
      labels: {
        recipient: {
          title: 'Recipient',
          addExisting: 'Add existing',
          addNew: 'Add new',
        },
        wallet: {
          labels: {
            title: 'Wallet',
            addExisting: 'Add existing',
            addNew: 'Add new',
          },
        },
        share: {
          labels: {
            title: '% secondary sale',
            helpText:
              'Total royalties are typically between 8-10% on secondary sales',
          },
        },
      },
      errors: {
        name_required: 'The name is required',
        address_not_valid: 'The address you entered is not valid',
        share_bad_input: 'Percentage must be a number',
        share_above_100:
          'Adding this recipient would take the secondary percentage sales to above 100',
        share_precision: 'Percentage must be 2 decimal points or less',
        wallet_already_added: 'This wallet has already been added',
      },
    },
  },
  certificatePage: {
    title: 'Certificates',
    actionText: 'Choose',
    emptyState: {
      heading: 'Create Certificates of Authenticity',
      body: 'Add COAs to products in your inventory',
      linkText: 'Create COAs',
    },
    loadingModal: {
      title: 'Update in progress',
      heading:
        'Changes may not be saved if you navigate away from this page before it is finished.',
    },
    successToast: 'Products updated',
    failedToDelete: 'Certificate could not be deleted. Please try again.',
    bannerTitle: 'Sorry, something went wrong',
    nftFail: {
      toast: 'NFT products not added',
      banner: {
        title: 'NFT products not added',
        message:
          'Certificates of Authenticity for NFTs are issued automatically.',
      },
    },
    learnMore: 'Learn more',
  },
  dashboard: {
    artBlocksBanner: {
      title: 'Sell generative art',
      message: 'Register your interest or request contract integration',
    },
    printUiBanner: {
      title: 'Our new print service',
      message: 'Transform your NFTs into prints you can sell.',
      link: 'See more on our help center.',
    },
    gettingStartedBanner: {
      title: 'Get started with Verisart',
      message:
        'Watch our video to learn how to sell NFTs, add tokengates and create certificates, all in just a few minutes',
    },
    new: {
      title: 'Welcome to Verisart',
      helpGuides: {
        title: 'Help guides',
        minting: {
          title: 'NFT minting',
          subtitle: 'Get started with minting and selling NFTs',
          buttonText: 'Learn more',
        },
        certification: {
          title: 'Certification',
          subtitle: 'Issue Certificates of Authenticity automatically',
          buttonText: 'Learn more',
        },
        tokengating: {
          title: 'Tokengating',
          subtitle: 'Reward NFT holders with exclusive access to merchandise',
          buttonText: 'Learn more',
        },
        printService: {
          title: 'Print Store',
          subtitle: 'How to launch your print store',
          buttonText: 'Learn more',
        },
        customerExperience: {
          title: 'Customer experience',
          subtitle: 'Understand what your customers will see on your store',
          buttonText: 'Learn more',
        },
        creators: {
          title: 'Add creators',
          subtitle:
            'Become a verified creator or request permission from creators you represent',
          buttonText: 'Learn more',
        },
        gifting: {
          title: 'Gift NFTs',
          subtitle:
            'Let your customers gift NFTs that arrive on a specific day',
          buttonText: 'Learn more',
        },
      },
      increaseTrust: {
        title: 'Increase trust with your customers',
        subtitle: 'Add the Verisart logo to your store',
        button: 'Choose logo',
        chooseLogoModal: {
          title: 'Add logo',
          subtitle_one: 'Choose a light or dark logo to add to your store',
          subtitle_two:
            'Add the code to a liquid template of where you want the logo to go e.g. footer.liquid',
          addLightLogoButton: 'Add light logo',
          addDarkLogoButton: 'Add dark logo',
          downloadText: 'or download the logo file',
        },
      },
      primaryBoxes: {
        mint: {
          title: 'NFTs',
        },
        tokenGate: {
          title: 'Tokengates',
        },
        certificate: {
          title: 'Certificates of Authenticity',
        },
        prints: {
          title: 'Prints',
        },
      },
      secondaryBoxes: {
        settings: {
          title: 'Account settings',
          subtitle: 'Manage contracts, creators, etc',
        },
        plan: {
          subtitle: 'Manage',
          title: (...args: string[]) => `You're on the ${args[0]} plan`,
        },
      },
    },
    heading: 'Dashboard',
    subtitle: 'Manage plan',
    getStarted: {
      title: 'Get started with Verisart',
      guidanceText: 'Sell NFTs',
      guidanceText2: 'Add tokengates',
      guidanceText3: 'Create certificates',
      buttonGetStarted: 'Get started',
    },
    tutorialSection: {
      heading: 'Show customers what they receive',
      tabs: {
        1: {
          tabName: 'Instructions',
          heading: "Optimize your customers' experience",
          textOne:
            'Show your store is certified by adding the snippets in this widget to your theme.',
          stepOne: 'Step 1',
          stepOneOne: 'Press the button to add the snippet to your site',
          stepTwo: 'Step 2',
          stepTwoOne:
            'Go to Online store > Themes > Current theme > Actions > Edit code',
          stepTwoTwo: 'Find the relevant template file',
          stepTwoThree: 'Paste the code',
          image: '/edit-code.png',
          imageAlt:
            'Image of edit code option in actions dropdown on themes page',
        },
        2: {
          tabName: 'Add Verisart logo',
          stepOne: 'Step 1',
          stepOneOne:
            'Click or tap the button below to add a black or white logo to your site’s footer.',
          stepTwo: 'Step 2',
          stepTwoOne:
            'Add the code to a liquid template of where you want the logo to go e.g. footer.liquid',
          heading: 'Display the Verisart Certified logo',
          textOne:
            'Reassure customers that your shop uses Verisart certification.',
          buttonOne: 'Add white logo',
          textTwo: 'Or add a dark version if you have a lighter background:',
          buttonTwo: 'Add black logo',
          forWhiteLogo: 'For white logo:',
          forDarkLogo: 'For dark logo:',
        },
        3: {
          tabName: 'Product page',
          heading: 'Show that a certificate is included',
          stepOne: 'Step 1',
          stepOneOne:
            'Click or tap the button below to add a black or white badge (light background) to your product pages.',
          stepTwo: 'Step 2',
          stepTwoOne:
            'Find the product title on your Product page, the file will be probably called product-template.liquid.',
          stepThree: 'Step 3',
          stepThreeOne: 'Add the line below under the product title.',
          buttonOne: 'Add white badge',
          lightCodeHeading: 'For white badge:',
          darkCodeHeading: 'For black badge:',
          buttonTwo: 'Add black badge',
        },
        4: {
          tabName: 'Cart',
          heading: 'Show that a certificate is included',
          stepOne: 'Step 1',
          stepOneOne:
            'Press the button below to add a custom snippet to your cart and checkout.',
          stepTwo: 'Step 2',
          stepTwoOne: 'Look for the following line in product-template.liquid',
          stepThree: 'Step 3',
          stepThreeOne: 'Copy and paste the following line below it:',
          buttonOne: 'Add snippet',
          textContentTwo:
            'Look for the following line in product-template.liquid',
          textContentThree: 'Add this line directly below it',
          toNote:
            'NB: This step is required in order for the Order confirmation email and Order confirmation screens to show the certificate',
        },
        5: {
          tabName: 'Order confirmation email',
          heading: 'Show that a certificate is included',
          stepOne: 'Step 1',
          stepOneOne: 'Find the Order confirmation email template under ',
          stepTwo: 'Step 2',
          stepTwoOne: 'Look for the following line:',
          stepThree: 'Step 3',
          stepThreeOne: 'Copy and paste the following line below it:',
          toNote:
            'NB: Please ensure you have followed the instructions in Cart tab in order for this to work',
        },
        6: {
          tabName: 'Order confirmation screen',
          heading:
            'Alert your customers that they will receive an email with their certificate',
          textContentOne:
            'Show an information panel on the order confirmation screen:',
          stepOne: 'Step 1',
          stepOneOne: 'Go to ',
          stepTwo: 'Step 2',
          stepTwoOne: 'Copy and paste the following line:',
          toNote:
            'NB: Please ensure you have followed the instructions in Cart tab in order for this to work ',
        },
      },
    },
  },
  certificationPanel: {
    title: 'Product status',
    type: 'Certificate type',
    validation: 'Object details validation',
    nftLabel: 'Generate NFT',
    blockchain: 'Blockchain',
    certificateLabel: 'Certify product',
    collectionsListIntro: 'Certified by the following collections:',
    contractAddress: 'Contract address',
    nftRoyaltyAddress: 'Royalty address',
    nftRoyaltyBps: 'Royalty BPS (ex. for 10%  put the value 1000)',
  },
  qrStickerPanel: {
    title: 'Add NFC chip or QR sticker',
    checkbox: 'Product ships with a tamper-proof NFC sticker or QR tag',
    qrStickerAttached: 'Activated',
    qrStickerNotAttached: 'Click box to activate',
    upsell: 'Order printed products',
    learnMore: 'Learn more',
    hasPurchasedSticker:
      'Activate to indicate a link to an NFC chip or a QR sticker to this product',
    hasNotPurchasedSticker:
      'Create a secure link between your physical & digital work with our NFC chips or QR stickers',
  },
  variantTitleThumbnail: {
    back: 'Back to product',
  },
  productsSection: {
    title: 'Products',
    subTitle: 'Select products to generate NFTs or certificates.',
    table: {
      tabs: {
        1: 'Collections',
        2: 'Products',
        3: 'Variants',
      },
      productStatus: {
        certified: 'Certified',
        notCertified: 'Not certified',
        checkVariants: 'Review variants',
        validated: 'Validated',
        notValid: 'Not valid',
        on: 'On',
        failed: 'Failed',
        off: 'Off',
        certifiedPlusQr: 'Certified + Linked QR',
      },
      headers: {
        collection: '',
        certificateType: '',
      },
      tableActions: {
        turnOff: 'Turn off',
        certify: 'Mark as certified',
        decertify: 'Mark as not certified',
        edit: {
          product: 'Edit products',
          variant: 'Edit variants',
        },
        sorting: {
          title: 'Sort by',
          buttonTitle: 'Sort',
          alpha: 'Title A-Z',
          alphaReverse: 'Title Z-A',
          creationDateReverse: 'Created (oldest first)',
          creationDate: 'Created (newest first)',
        },
        updateError:
          'One or more of the selected products are part of a certified collection. To mark them as not certified please remove them from the collection.',
      },
      buttons: {
        productsRow: 'View products',
        productsRowAccessibilityLabel: 'view products button',
      },
      emptyResultTitle: 'No products found',
      emptyResultSubtitle: 'Try removing some filters',
    },
    reviewPopup: {
      heading: 'Review metafields',
      button: 'Edit',
      metafieldGuide: 'Metafield guide',
    },
    metaDataForms: {
      subheading: 'Object details',
      titleImgAlt: 'Image of currently editable products metadata',
      basic: {
        heading: 'Basic',
        artist: 'Artist/creator',
        productTitle: 'Title',
        description: 'Description',
        images: 'Images',
        image: 'Image',
        edition: {
          editionTypeLabels: {
            single_edition: 'Single edition',
            single_ap: 'Single AP (Artist Proof)',
            multiple_unnumbered_edition: 'Multiple (unnumbered)',
            multiple_unnumbered_ap: 'Multiple AP (Artist Proofs unnumbered)',
            multiple_auto: 'Multiple (auto-numbered)',
          },
          helpCenterText: 'See how NFT editions work',
          heading: 'Edition',
          subheading: 'Is this work an edition?',
          editionType: 'Edition type',
          editionNumber: 'Edition number',
          editionVolume: 'Edition volume',
          apNumber: 'Artist Proof number',
          apVolume: 'Artist Proof volume',
          autoNumberEditions: 'Numbering starts from',
          editionNote: 'Edition note',
          upgradePlan: 'Upgrade to Premium to generate multiple editions',
        },
        imageMissingError: 'Primary image is missing',
      },
      extended: {
        heading: 'Recommended',
        objectType: 'Object type',
        medium: 'Medium',
        productionYear: 'Production year',
        width: 'Width',
        height: 'Height',
        depth: 'Depth',
        measurement: 'Measurement',
      },
      enhanced: {
        heading: 'Extended',
        productionLocation: 'Production location',
        currentLocation: 'Current location',
        inventoryNumber: 'Inventory number',
        note: 'Note',
        additionalFiles: 'Additional files',
      },
      variants: {
        title: 'Variants',
      },
      objectTypes: {
        none: 'None',
        painting: 'Painting',
        sculpture: 'Sculpture',
        print: 'Print',
        design: 'Design',
        collectible: 'Collectible',
        photo: 'Photo',
        drawing: 'Drawing',
        mixed_media: 'Mixed media',
        furniture: 'Furniture',
        book: 'Book',
        ephemera: 'Ephemera',
        digital_artwork: 'Digital',
        nft: 'NFT',
        video: 'Video',
        other: 'Other',
      },
      success: 'Product updated',
      validationError: (params: string[]) =>
        params[0] === '1'
          ? '1 Validation error'
          : `${params[0]} Validation errors`,
      formError: 'An error occured',
      errors: {
        editionType: 'Must select an edition type',
      },
    },
    incorrectMetaFields: {
      title: 'Action required',
      message: 'These issues need your attention',
      action: 'Learn more',
      titleUnsoldNFT: 'NFT sold',
      messageUnsoldNFT:
        'This imported NFT was sold. You should delist it from your store.',
      existingNFTPermissionsResolveNow: 'Resolve now',
    },
    royalties: 'No royalties',
    traitsLabel: 'Enter JSON code',
    helpText: 'Must be in JSON format',
    labelActionContent: 'See example',
    stagedAssetAlt: 'Display Image',
    errors: {
      general:
        'Something went wrong on our end. Please contact customer support.',

      tooLarge:
        'Sorry, this file is too large. Usual limits are 40MB for images and 300MB for other files. Images also have a 20 megapixels limit.',
      NFTFileTypes:
        'Unsupported file type. Upload one of the following file types: jpg, png, gif, mp4, glb, gltf',
      COAFileTypes:
        'Unsupported file type. Upload one of the following file types: jpg, png, gif, mp4, mov, wmv, webm, glb, gltf',
      imageMissingNFT:
        'Must enter a value. Upload one of the following file types: jpg, png, gif, mp4, glb, gltf',
      imageMissingCOA:
        'Must enter a value. Upload one of the following file types: jpg, png, gif, mp4, mov, wmv, webm, glb, gltf',
    },
    usingDefaultCreator: (...args: string[]) =>
      `Set to default creator: ${args[0]}`,
    defaultCreatorHintPrefix: 'or ',
    defaultCreatorHintLink: 'set a default creator',
  },
  settings: {
    page: {
      title: 'Settings',
      toastSettingsSaved: 'Settings updated',
      toastSettingsValidationFailed:
        'Settings not saved. Please check for errors.',
      toastSettingsSaveError: 'Settings not saved. Please try again.',
      plan: {
        linkText: 'Verisart.com',
        cognitoAccountText: (...args: string[]) =>
          `This store is linked to the account for ${args[0]} on`,
        shopifyAccountText: 'This store is linked to an account on',
      },
    },
    accountSettings: {
      title: 'Account settings',
    },
    depay: {
      bulletPoints: {
        first: {
          text: '1. Install crypto payment provider DePay',
          link: 'Install',
        },
        second: {
          text: '2. Set up DePay',
          link: 'Learn more',
        },
        third: {
          text: '3. Grant Verisart permissions for direct to wallet transactions',
        },
      },
      cardTitle: 'Deliver NFTs directly to customer wallets',
      activatedAction: 'Review',
      ctaAction: 'Setup',
      ctaActionDisabled: 'Redirecting...',
      success: {
        bannerTitle: 'Deliver NFTs directly to wallets',
        bannerDescription: "You've successfully linked your DePay account",
      },
      wrong_shop: {
        bannerTitle: 'DePay connection failed',
        bannerDescription:
          'The account you are trying to connect is not set up for this shop',
      },
    },
    usageCharges: {
      currencyPost: 'USD',
      title: 'Usage charges',
      action: 'Manage',
      banner: {
        title: 'You are close to exceeding your usage limit',
        description: 'Take action now to continue minting',
        cta: 'Manage charges',
      },
      currentUsage: 'in usage charges to date.',
      capCharges: {
        pre: 'Usage charges will not exceed ',
        post: (...values: React.ReactNode[]) =>
          `without additional approval and will reset on ${values[0]}`,
      },
      verisartCharges: 'Verisart charges the following transaction fees:',
      noUsageCharges:
        'No usage charges found. Please contact customer support.',
    },
    creators: {
      title: 'Manage creators',
      description:
        "Link the creator(s) of your products to your shop's account.",
      manage: 'Manage',
      defaultCreatorLabel: 'Default creator',
      defaultCreatorNoneLabel: 'None',
      defaultCreatorMyselfLabel: 'Myself',
      defaultCreatorRepresentedLabel: 'Creators I represent',
    },
    emailCustomization: {
      title: 'Email customization',
      description:
        'Add your own branding and customize the messages customers receive.',
      manage: 'Manage',
      settingsPageTitle: 'Email customization',
      sendTestEmailAddress: 'Email address',
      sendTestEmailAddressRequired: 'Email address is required',
      sendTestEmailAddressFormatIncorrect:
        'Entered value does not match email format',
      sendTestEmailNoTemplateSelected:
        'At least one email template must be selected',
      docsCopy:
        'Guide to advanced customization, including a list of variables you can include in your templates.',
      emailLearnMore: 'Learn more',
      sendTestEmailSuccess: 'Test email sent',
      customHTMLSubjectLineLabel: 'Subject line',
      customHTMLBodyLabel: 'Email body (HTML)',
      ClearAllButton: 'Delete',
      sendTestEmailError: 'Test email not sent',
      submitCustomHTMLSuccess: 'Email updated',
      submitBasicBodySuccess: 'Email updated',
      submitBasicBodyError: 'Error Submitting body',
      deleteCustomHTMLSuccess: 'Email template deleted',
      testEmailSendErrorTitle: "Sorry, we couldn't send a test email",
      advancedEmailSetWarning:
        'Advanced template is active. Please delete to switch back to basic',
      testEmailSyntaxError: 'We have found a syntax error:',
      submitCustomHTMLError: 'Error submitting Custom Template',
      sendNFTTestEmail: 'Claim NFT',
      testEmailCardTitle: 'Send test',
      sendCertificateTestEmail: 'View Certificate of Authenticity',
      sendViewNFTTestEmail: 'View NFT',
      sendEmail: 'Send',
      customization: 'Customization',
      GlobalCustomizationsubheading:
        'Add your logo, colors and custom sender to emails your customers receive from the Verisart App.',
      subjectRequired: 'subject is required',
      bodyRequired: 'body is required',
      fromEmail: 'From email',
      GlobalCustomization: 'Global email customization',
      designTitle: 'DESIGN',
      logo: 'Logo',
      logoHint: 'The logo appears at the top of the email',
      logoUpload: 'or drop files to upload',
      logoClear: 'Clear custom logo',
      bodyText: 'Body text',
      COAbodyText: 'View Certificate of Authenticity',
      COABodyInformation:
        'Sent when customer orders a product including a Certificate of Authenticity.',
      COABodyInformationLong:
        'Sent to the customer when they complete the purchase of a product that includes a Certificate of Authenticity. Links to the COA page on Verisart.',
      bodyTextLength: (...values: React.ReactNode[]) =>
        formatted`${values[0]}/${values[1]} characters remaining`,
      nftBodyText: 'Claim NFT',
      nftBodyInformation:
        'Sent when customer orders an NFT product with most payment methods.',
      nftBodyInformationLong:
        'Sent to the customer when they complete the purchase of an NFT product with most payment methods. Links to the NFT Dashboard on your store where they can claim the NFT to their wallet.',
      nftBodyTextLength: (...values: React.ReactNode[]) =>
        formatted`${values[0]}/${values[1]} characters remaining`,
      viewNFTText: 'View NFT',
      viewNFTBodyInformation:
        'Sent when customer orders an NFT product with some crypto payment providers.',
      viewNFTBodyInformationLong:
        'Sent to the customer when they complete the purchase of an NFT product with DePay. Links to the NFT Dashboard on your store where they can view their NFT. (The NFT was already sent automatically to their wallet.)',
      viewNFTTextLength: (...values: React.ReactNode[]) =>
        formatted`${values[0]}/${values[1]} characters remaining`,
      buttonColorLabel: 'Button color',
      buttonColor: 'Button background',
      buttonColorHint: 'Choose a custom button color',
      buttonTextColor: 'Button text color',
      buttonTextColorHint: 'Choose a custom button text color',
      emailTitle: 'EMAILS',
      errorEmailAddressInvalid: 'Please enter a valid email',
      basicRadioLabel: 'Basic',
      advancedRadioLabel: 'Advanced',
      basicRadioHelp: 'Standard template',
      advancedRadioHelp: 'Your custom HTML',
      claimCoaCard: 'View Certificate of Authenticity',
      claimNftCard: 'Claim NFT',
      viewNftCard: 'View NFT',
      claimCoaEmail: 'View Certificate of Authenticity email',
      claimNFTEmail: 'Claim NFT email',
      viewNFTEmail: 'View NFT email',
      globalCustomizationToast: 'Email updated',
      deleteModalTitle: 'Are you sure?',
      deleteEmailTemplateModal:
        'Delete advanced email template? This cannot be undone.',
    },
    minting: {
      title: 'NFTs',
    },
    contracts: {
      title: 'Contracts',
      description: 'Create and manage custom contracts for NFT drops',
      manage: 'Manage',
      defaultContractLabel: 'Default contract',
      defaultContractNoneLabel: 'None',
    },
    plans: {
      title: 'Plan',
      description: (...values: React.ReactNode[]) =>
        `You're on the ${values[0]} plan`,
      manage: 'Manage',
      planUpdated: 'Plan updated',
    },
    royaltySplits: {
      title: 'Royalties',
      description: 'Earn a percentage of the secondary sales of your NFTs',
      manage: 'Manage',
      defaultRoyaltySplitLabel: 'Default royalties',
      defaultRoyaltySplitNoneLabel: 'None',
    },
    certificates: {
      title: 'Certificates',
      customizationsTitle: 'COA customizations',
      customizationsManage: 'Manage',
      customizationsDescription: 'Increase brand awareness with your own logo.',
      settingsPageTitle: 'Customizations',
      defaultObjectType: 'Default object type',
      deletedCustomization: 'Customization deleted',
      defaultObjectTypeDescription:
        'Choosing a default object type simplifies certificate creation by displaying relevant fields for all products. You can easily edit this later.',
      templateLabel: 'Default template',
      newTemplateLabel: 'Add new template…',
      templateNameLabel: 'Template name',
      logo: 'Logo',
      logoHint:
        'Your logo will appear at the top of the certificate. It may appear in provenance records.',
      logoClear: 'Clear custom logo',
      background: 'Background',
      backgroundDefaultLabel: 'Default',
      backgroundColorLabel: 'Color',
      backgroundImageLabel: 'Image',
      subheading: 'Subheading',
      customizationSaved: 'Customization updated',
      link: 'Link',
      customizationSaveFailed: 'Customization not saved. Please try again.',
      subheadingLength: (...values: React.ReactNode[]) =>
        formatted`${values[0]}/${values[1]} characters remaining`,
    },
    certificationTrigger: {
      ordersPaidLabel: 'Point of purchase (Default)',
      ordersPaidHelpText:
        'Claim emails sent when payment received. Customers are notified directly after placing their order.',
      ordersFulfilledLabel: 'Point of fulfillment',
      ordersFulfilledHelpText:
        'Claim emails sent when order is marked as fulfilled. Choose this option if your order cancellation rate is high.',
      ordersFulfilledHelpTextWooCommerce:
        'Claim emails sent when order is marked as completed. Choose this option if your order cancellation rate is high.',
    },
    upload: {
      replaceImage: 'Replace image',
      selectImage: 'Select image',
    },
  },
  settingsSection: {
    mintingStatus: {
      learnMore: 'Learn more',
      title: 'Status',
      reapplyLink: 'Reapply',
      reapplyText: (date: string) => `You can reapply on ${date}`,
      testModeBadge: 'Test mode',
      approvedForGiftingBadge: 'Approved for gifting',
      approvedForSellingBadge: 'Approved for selling',
    },
    custodialWallets: {
      title: 'Minting wallet',
      walletTitle: 'Wallet address',
      listTitle: 'Wallet balance',
      recoveryLink: 'Reveal secret phrase',
    },
    customGwei: {
      title: 'Gas price limit',
      maximumCost: {
        text: "GWEI limit is the maximum you're willing to pay when you’re covering costs",
        linkText: 'Learn more',
      },
      normalCost: {
        text: 'Current cost is the current cost based on gas price and exchange rates.',
      },
      learnMore: 'Learn more',
      tooltip:
        'Fees on Polygon and Base are covered by Verisart as part of your plan',
    },
    feeRange: {
      title: 'Estimated costs',
      contractDeployment: 'Contract deployment',
      mint: 'Mint 1 NFT',
      learnMoreDescription: 'How much your NFT will cost.',
      learnMoreLinkText: 'Learn more',
      headings: {
        heading_one: 'Estimated costs',
        heading_two: 'Polygon',
        heading_three: 'Ethereum',
      },
    },
    onlyAvailablePtOne: 'This feature is available to',
    onlyAvailablePtTwo: 'subscribers',
    features: {
      emailCustomization: {
        title: 'Customize email',
        description:
          'Add your own logo, button style and body copy to the email customers receive.',
      },
      certificateCustomization: {
        title: 'Customize certificate',
        description:
          'Increase brand awareness with your own logo and custom background.',
      },
      manageArtists: {
        title: 'Manage creators',
        additionalInfo:
          'Add creators to create full Certificates of Authenticity.',
        button: 'Manage creators',
      },
      manageContracts: {
        title: 'Manage contracts',
        additionalInfo: 'View custom contracts for NFT drops.',
        button: 'Manage contracts',
      },
      sentTestEmailForm: {
        title: 'Send example email',
        description:
          'Preview the email your customers receive with links to their certificates.',
        sentOne: 'We have sent an email to',
        sentTwo: '. Check your inbox.',
        button: 'Send email',
      },
      certificationPreferenceForm: {
        title: 'Set certificate trigger',
        description:
          'At what point should customers receive their certificates?',
      },
      defaultObjectForm: {
        title: 'Object details: Default values',
        description: 'These values will be applied to all your products.',
        objectType: 'Object type',
      },
    },
    customiseCertificate: {
      headerThree: 'Subheading',
      maxLength: 'Max length: 40 characters',
      errorMessage: "Sorry, we couldn't save your customization",
    },
    customiseForm: {
      title: 'Logo',
      subTitle:
        'Your logo will appear at the top of the certificate. It may appear in provenance records.',
    },
    customiseEmailForm: {
      title: 'Customize email',
      subTitle:
        'Add your own logo, button style and body copy to the email customers receive.',
      form: {
        titleOne: 'From email',
        titleTwo: 'Logo',
        subtitleTwo: 'The logo appears at the top of the email',
        titleBodyText: 'Certificate created body',
        titleNftBodyText: 'NFT created body',
        titleFour: 'Button color',
        subTitleFour: 'Choose a custom button color',
        titleFive: 'Button text color',
        subTitleFive: 'Choose a custom button text color',
        maxChars: '400 characters remaining',
        button: 'Save',
      },
      sections: {
        info: {
          ptOne: 'Emails are being sent to your customers from',
          ptTwo: '. You can update this or',
          button: 'reset to the default',
        },
        warning: {
          ptOne: `Your domain name must be verified before emails will use this
                    address. Until this is done, emails will be sent from Verisart's email address
                    and your email will be used as the reply-to address.
                    Please contact support@verisart.com to verify your domain.`,
          button: 'resend it',
        },
        certification: {
          title: 'Activate certification to get started',
          content: 'Activate',
        },
      },
    },
    certificationPreferences: {
      optionOne: {
        label: 'Point of purchase (Default)',
        helpText:
          'Certificates issued when payment is captured. Customers are notified directly after placing their order.',
        id: 'ORDERS_PAID',
      },
      optionTwo: {
        label: 'Point of fulfilment',
        helpText:
          'Certificates issued when order is marked as fulfilled. Choose this option if your order cancellation rate is high.',
        id: 'ORDERS_FULFILLED',
      },
      successMessage:
        'Setting updated. Certificates will be created at Point of ',
      errorMessage:
        'Sorry, we couldn’t update this setting. Resolve any open orders and try again.',
    },
  },
  planSetupModal: {
    title: 'Choose a plan for your 14-day trial:',
    plans: {
      premium: {
        subtitle: 'For individual creators',
        listItemOne: 'Unlimited certificates',
        listItemTwo: 'Customize certificate design',
        listItemThree: 'Certify limited editions',
        tryButton: 'Try Premium',
        costCopy: '$10 per month after trial',
      },
      business: {
        subtitle: 'For galleries and businesses',
        listItemPrefix: 'Everything in Premium plus',
        listItemOne: 'Manage multiple artists or creators',
        listItemTwo: 'Customize customer emails',
        tryButton: 'Try Business',
        costCopy: '$200 per month after trial',
      },
    },
  },
  planSection: {
    title: 'Choose a plan',
    currentTrial: (params: string[]) =>
      `You are on a 14-day ${params[0]} trial`,
    current: (params: string[]) => `You're on the ${params[0]} plan`,
    renew: (params: string[]) => `Your plan will renew on ${params[0]}`,
    upgraded: (params: string[]) =>
      `You have successfully upgraded to ${params[0]}`,
    downgraded: (params: string[]) =>
      `You have successfully downgraded to ${params[0]}`,
    plans: {
      basic: {
        getPlanCopy: 'Get Basic',
        listItemOne: 'Create certificates for a single artist or creator',
        listItemTwo: 'Share via social media',
        listItemThree: 'Print certificate PDFs',
        listItemFour: 'Transfer certificate to new owner',
        costCopy: 'Free',
      },
      premium: {
        getPlanCopy: 'Get Premium',
        cancelButtonText: 'Cancel Premium',
        listItemOne: 'Everything in Basic plus',
        listItemTwo: 'Customize the certificate design',
        listItemThree: 'Set privacy controls',
        listItemFour: 'Instantly certify limited editions',
        listItemFive: 'Download secure artwork label',
        costCopy: '$10/month',
      },
      business: {
        getPlanCopy: 'Get Business',
        cancelButtonText: 'Cancel Business',
        listItemOne: 'Everything in Premium plus',
        listItemTwo: 'Manage multiple artists or creators',
        listItemThree: 'Customize emails sent to customers',
        costCopy: '$200/month',
      },
    },
  },
  creatorsSection: {
    completeSetup: 'Complete setup',
    ctaManage: 'Manage creators at Verisart.com',
    ctaManageButton: 'Manage',
    title: 'Creators',
    subTitle: 'See which creators you have linked to your account on ',
    link: 'Verisart.com',
    emptyState: {
      title: 'Add creators',
      subTitle: 'Select an artist or creator and link them to your account.',
      buttonText: 'Add creator',
    },
    personalTable: {
      heading: 'Myself',
      emptyTitle: 'You will appear here as a creator',
    },
    representsTable: {
      heading: 'Represented',
      emptyTitle: 'Manage represented artists and creators',
      emptyTitleBusinessUser: 'Create certificates for creators you represent',
      emptyButtonTextBusinessUser: 'Go to Verisart.com',
      emptyButtonText: 'View plans',
    },
  },
  artistSelector: {
    groupTitle: {
      self: 'Myself',
      represents: 'Creators I represent',
      all: 'All other creators',
    },
    title: 'Creator',
    optionNone: 'None',
  },
  pricing: {
    title: 'Plan',
    expiredBanner: {
      title: 'You’re not on an active plan',
      message:
        'Subscribe to a plan to activate the App. Do you need a free trial extension? ',
      messageLink: 'Get in touch',
    },
    cancelBanner: {
      title: 'Subscription cancelled',
      message: 'You have successfully cancelled your subscription.',
    },
    tabs: {
      type: {
        content: {
          individual: 'Individual',
          business: 'Business',
        },
      },
      frequency: {
        content: {
          month: 'Monthly',
          year: 'Annual',
        },
      },
    },
    badge: {
      current: 'Current plan',
      recommended: 'Recommended plan',
    },
    plan: {
      get: (...args: string[]) => `Get ${args[0]}`,
      manage: 'Manage on Verisart',
    },
    currentPlan: (...args: string[]) => `You are on the ${args[0]} plan.`,
    pausedPlan: 'Your plan is paused.',
    renewPlan: '{0} Renew your plan',
    comparePlans: 'Compare plans',
    cancelPlan: {
      button: 'Cancel',
      confirmCancel: 'Confirm',
      cancelModalBody:
        'We are sorry to see you go. If you change your mind, you can resubscribe',
      cancelModalTitle: 'Cancel your plan',
    },
  },
  dropFormPage: {
    emptyState: {
      title: 'Create NFT drop',
      subtitle: 'Set up NFTs to sell on your store',
      buttonText: 'Create NFT drop',
    },
  },
  dropForm: {
    collectorRewardUploadSuccessMessage:
      'File uploaded successfully. Please save the form to apply changes.',
    productsWithIssuesBanner: {
      title: 'Some products in your drop need review',
      body: ' Check the list below and edit products that need review. You need to do this before your drop goes live.',
    },
    contractNotDeployedBanner: {
      title: 'Contracts pending',
      body:
        'One of the contracts associated with this drop has not been deployed. Your drop will not be able to go ahead' +
        ' until this is resolved.',
      itemsAffected: 'Items affected:',
      learnMore: 'Learn more about how to resolve this',
      contract: (...args: string[]) => `Drop contract "${args[0]}"`,
      paymentSplitter: (...args: string[]) => `Payment splitter "${args[0]}"`,
    },
    navigateAwayPrompt:
      'Leaving this page will delete all unsaved changes. Are you sure you want to leave?',
    deleteCta: 'Delete',
    deleteModalTitle: 'Are you sure?',
    deleteModalBody: 'Really delete this drop? This action cannot be undone.',
    titleCreate: 'Create drop',
    titleEdit: 'Edit drop',
    emptyState: {
      title: 'Create NFT drop',
      subTitle: 'Set up NFTs to sell on your store',
      buttonText: 'Create NFT drop',
    },
    dropName: 'Drop name',
    dropNameError: 'Drop name is required',
    highlightId: 'HighLight ID',
    highlightError: 'ID is required for Highlight drops',
    projectId: 'Project ID',
    projectIdError: 'Project ID is required for Art Blocks Drops',
    chainInfo: 'Blockchain information',
    chain: 'Blockchain',
    contract: 'Contract',
    createContract: 'Create new contract',
    createTokengate: 'Create new tokengate',
    createRoyaltysplit: 'Create new royalty split',
    nftProducts: {
      title: 'NFT products',
      subtitle: 'Select the products you want to include in your drop',
      emptyState: 'Search or browse to add products',
      alreadyInDrop: (...args: string[]) => `Already in drop: ${args[0]}`,
      sold: 'Sold',
    },
    gasFeeCustomization: {
      title: 'Who pays gas fees?',
      merchantPays: 'Store pays gas fees',
      merchantHelpText: 'Paid from your minting wallet',
      customerPays: 'Customer pays',
      customerHelpText: 'Paid by customer on claim',
      unsupportedContract:
        "The contract you've selected only supports store pays",
    },
    royalties: {
      title: 'Royalties',
      subtitle: 'Only applicable for secondary sales',
      option: 'Choose an option',
      noneTitle: 'No royalties',
      noneSubtitle: "Don't include royalty information on NFTs in this drop",
      contractTitle: 'Add royalties',
      contractSubtitle: 'Set a royalty split for all the NFTs in this drop',
    },
    enhance: {
      title: 'Enhance your drop',
      subtitleOne: 'Unlockable rewards',
      subtitleTwo: 'Tokengate',
      rewardsCheckbox: 'Include collector rewards for all NFTs in the drop',
      rewardsCheckboxDetail: 'Edit a product to add unique collector rewards',
      tokengateCheckbox: 'Allow future owners',
      createTokengate: 'Create tokengate',
      selectTokengate: 'Select tokengate',
    },
    helpPanel: {
      title: 'How to set up your drop',
      link: 'Read our Shopify merchant handbook',
      1: '1',
      titleOne: 'Choose your payment provider',
      subtitleOne:
        'Complete the Shopify eligibility form to sell NFTs and choose your payment method.',
      2: '2',
      titleTwo: 'Define your drop',
      subtitleTwo:
        'Name your drop, select contract, choose your blockchain and set up royalties.',
      3: '3',
      titleThree: 'Choose products',
      subtitleThree: 'Select products and complete creator setup. ',
      subtitleThreeLink: 'Learn more',
      4: '4',
      titleFour: 'Enhance your drop',
      subtitleFour:
        'Add unlockable rewards to share exclusive files or images with token holders.',
    },
    toast: {
      missingChain: 'Blockchain is required',
      createSuccess: 'Drop created',
      editSuccess: 'Drop updated',
      editError: 'Error creating drop',
      tokengateError: 'Contract still pending. Try again.',
      selectTokenGateError: 'Contract is required',
      save: 'Save',
    },
    deleteBanner: {
      title: 'Drop deleted',
      body: 'You have successfully deleted this Drop',
    },
    errorBanner: {
      title: 'Sorry, something went wrong',
      body: 'Please contact customer support. Something went wrong when trying to edit your drop',
    },
    editBanner: {
      title: 'Drop updated',
      body: 'The drop was successfully updated',
    },
    errors: {
      noSave: 'Sorry, there was error while saving',
    },
  },
  contractsPage: {
    pendingAddress: 'Pending',
    title: 'Contracts',
    createButton: 'Create contract',
    listItems: {
      deployer: 'Deployer address',
      contractType: 'Contract type',
      owner: 'Owner address',
      description: 'Description',
      viewCollection: 'View collection',
      viewScan: (...args: string[]) => `View on ${args[0]}scan`,
      preminted: 'Preminted',
      onDemand: 'On-demand',
      dropType: 'Type',
    },
    emptyState: {
      // title: 'Create a new contract today',
      // subtitle: 'Create a new contract to sell NFTs',
      title: 'You have no existing contracts',
      // subtitle: 'Create a new contract to sell NFTs',
      button: 'Create contract',
    },
  },
  reportsPage: {
    title: 'Reports',
  },
  printPage: {
    heading: 'Prints',
    button: 'Create',
    empty: {
      heading: 'Create a new print',
      body: 'Create a new print to give your customers a physical representation of their NFT',
      link: 'Create',
    },
  },
  tokenGatePage: {
    checkoutFunctionBanner: {
      title: 'Setup recommended',
      line1:
        'To ensure your tokengates are secure, you are advised to activate the checkout rule.',
      step1Part1: 'Go to the',
      step1Part2: 'scroll to the bottom, and click',
      step1Part3: 'Add rule',
      step2Part1: 'Select',
      step2Part2: 'Verisart secure tokengate',
      step3Part1: 'Click',
      step3Part2: 'Activate',
      step3Part3: 'to turn on secure tokengates',
    },
    list: {
      edit: 'Edit',
      conditions: 'Conditions',
      redemptionLimit: 'Redemption limit',
      chain: 'Blockchain',
      lastUpdated: 'Last updated',
      consumed: 'Consumed',
      products: 'Products',
      redemptions: 'Redemptions',
      csvDownload: 'CSV download',
    },
    empty: {
      heading: 'Create a new tokengate',
      body: 'Create a new tokengate to limit access to products based on NFT ownership',
      link: 'Create',
    },
    heading: 'Tokengates',
    subtitle: 'Read our recommendations for verifying tokengates.',
    learnMore: 'Learn more',
    button: 'Create',
    upgrade: 'Upgrade',
  },
  productPicker: {
    productError: (...args: Array<string>) =>
      `At least ${args[0]} product is required`,
  },
  printDropForm: {
    error: {
      noSave: 'Sorry, we couldn’t save your print',
      gifError:
        'Please contact customer support. We are unable to generate the product image.',
      gifLoading:
        'Product image is still generating. please wait until this is done until you save.',
    },
    deleteBanner: {
      title: 'Print deleted',
      body: 'You have successfully deleted this print',
    },
    deletePrintDrop: 'Really delete this print? This cannot be undone.',
    savePrintDrop: 'Do you want to save without a product image?',
    createBanner: {
      title: 'Print created',
      body: 'The print was successfully created. See our',
    },
    editBanner: {
      title: 'Print updated',
      body: 'The print was successfully updated',
    },
    productDescriptionDefault:
      'Available exclusively to NFT holders. Simply connect your wallet and get your personalized print featuring your own NFT. Giclée print on Hahnemühle German etching paper with a textured finish and velvety matte surface. Includes a digital Certificate of Authenticity powered by Verisart and signed by the artist. Each print comes with a tamper-proof NFC tag linking the physical print to the digital certificate. Simply tap to activate! Ships from the US, UK and Europe. International customers are responsible for import fees due upon delivery. ALL SALES FINAL.',
  },
  tokenGateForm: {
    helpBanner:
      'To learn more about setting up secure tokengates, configuring access and adding a token lookup to your theme, click ',
    helpBannerLink: 'here',
    helpBannerFullStop: '.',
    primaryAction: 'Save',
    banner1: {
      title: 'Some tokens have been redeemed',
      body: 'Updating this tokengate will change the criteria for NFT holders going forward.',
    },
    fields: {
      contractAddress: 'Contract address',
      artBlocksProjectId: 'Filter by Art Blocks project ID',
      checkbox: 'End date',
    },
    deleteButton: 'Delete',
    deleteBanner: {
      title: 'Tokengate deleted',
      body: "You have successfully deleted this tokengate. Don't forget to switch your product theme.",
    },
    errorBanner: {
      title: 'Something went wrong',
      body: 'Please contact customer support. Something went wrong when trying to delete your tokengate',
    },
    createBanner: {
      title: 'Tokengate created',
      body: 'The tokengate was successfully created. See our',
    },
    editBanner: {
      title: 'Tokengate updated',
      body: 'The tokengate was successfully updated',
    },
    gatedProduct: {
      label: 'Gated product',
      subtitle: 'Select the product you want to tokengate',
    },
    print: {
      title1: 'This tokengate was created as part of the',
      title2: 'Print',
      title3: 'service',
      subtitle: 'Some information cannot be edited',
    },
    dateTime: {
      startDate: 'Start date',
      startTime: 'Start time',
      startDateHint:
        'Before the start date and time, the product is not available for purchase',
      endDate: 'End date',
      endTime: 'End time',
      endDateHint:
        'After the end date and time, the product will be available to all users',
      snapshotDate: 'Snapshot date',
      snapshotTime: 'Snapshot time',
    },
    error: {
      fieldsErrors: 'Please correct the errors below and try again',
      snapshot: 'Snapshot must be in the future',
      startTime:
        'Start date and time must be at least an hour after the snapshot time',
      noSave: 'Sorry, we couldn’t create your tokengate',
      actionRequired: 'Action required',
      themeNotApplied: 'Please correct the tokengate theme. ',
      disscountMissing:
        'Shopify discount is missing. Please click here to resolve the issue.',
      learnMore: 'Learn more',
      missingFields: 'Please fill in the missing fields',
      invalidAddress: 'The contract address is invalid',
      discountLimit: 'You have reached the limit of 5 tokengate discounts',
      duplicateGate:
        'A gate with the same contract address and product already exists',
      noSaveChanges: "Sorry, we couldn't save your changes",
      nameRequired: 'Name is required',
      contractAddress: 'Contract address is required',
      artBlocksProjectId: 'Art Blocks project ID is required',
      chain: 'Blockchain is required',
      endTimeBeforeStart: 'End date must be after start date',
      startTimeAfterEnd: 'Start date must be before end date',
    },
    cardTitle: 'Tokengate information',
    lastModified: 'Last modified',
    maxPerWalletAndPerTokenError:
      'The maximum items that can be redeemed per token must be greater or equal to maximum per wallet',
    maxPerWalletPerToken: 'Redeemable items per wallet',
    maxPerTokenHelpText:
      'The total items that can be redeemed per token; use -1 for unlimited',
    maxPerWalletPerTokenHelpText:
      'The total items that can be redeemed per wallet (per token owned); use -1 for unlimited',
    maxPerToken: 'Redeemable items per token',
    gateType: {
      title: 'Gating Rule',
      subheading: ' Who is allowed access? Learn more about contract types ',
      link: 'here',
      currentOwnersGateType: 'Current owners',
      currentAndFutureOwnersGateType: 'Current and future owners',
      snapshotGateType: 'Snapshot of owner list (ERC-721 or ERC-1155)',
      futureOwnersGateType: 'Allow future owners (ERC-721)',
      futureOwnersGateHelper: 'Future owners will be able to redeem',
      noFutureOwnersGateType: 'Do not allow future owners (ERC-721)',
      noFutureOwnersGateHelper: 'No future owners allowed to redeem this token',
      advancedGateType: 'Advanced settings',
      advancedHelper:
        'Set your own rules by limiting redemption times per token and wallet',

      onePerWalletGateType: 'One Redemption per Wallet',
      onePerWalletHelper:
        'Each wallet is allowed a single redemption by using this token, perfect for ensuring broad yet controlled access',
      singlePhysicalItemGateType: 'Single-Use Token',
      singlePhysicalItemHelper:
        'Each token is valid for a single use and becomes inactive after redemption',
      memberClubGateType: "Member's club token",
      memberClubHelper: 'Allows for unlimited access to the product',
      currentOwnersHelper: 'Wallets can only redeem once. ERC-721',
      currentAndFutureOwnersHelper:
        'Tokens can be transferred to new wallets and redeem again. ERC-721',
      snapshotHelper:
        'Owners at a specific moment in time. ERC-721 or ERC-1155',
    },
    filters: {
      title: 'Token filters',
      noneFilterTypeLabel: 'Include all tokens',
      traitsFilterTypeLabel: 'Filter by trait',
      includingTraitRuleOption: 'Include',
      excludingTraitRuleOption: 'Exclude',
      traitRuleHint:
        'Tokens must match all traits to be included or excluded from the gate',
      traitsLabel: 'Traits',
      traitNameLabel: 'Trait name',
      traitValueLabel: 'Trait value',
      traitNamePlaceholder: 'Name',
      traitValuePlaceholder: 'Value',
      traitNameValidationRequired: 'Name is required',
      traitValueValidationRequired: 'Value is required',
      traitDeleteLabel: 'Delete',
      addTrait: 'Add another trait',
    },
    deleteModalTitle: 'Are you sure?',
    deleteTokenGateModal:
      'Really delete this tokengate? This will reset your redemption counter. It cannot be undone.',
    allowFutureOwners: 'Allow future owners',
    contractPreview: {
      missingBlockchain: 'Select a blockchain to preview the contract',
      errorTitle: 'Unable to preview contract',
      errorMessage: 'You can still continue with this form',
    },
  },
  recoverWalletModal: {
    title: 'Reveal secret phrase',
    actionButton: 'Send email',
    helpButton: 'Learn more',
    errors: {
      tooManyRequests:
        'Sorry, there have been too many requests to try and recover the wallet. Please contact support@verisart.com',
      alreadyRecovered:
        'Sorry, your wallet has already been recovered. We only allow you to reveal the secret phrase once. If you need help, contact support@verisart.com',
      general: 'Sorry, something went wrong',
    },
    description:
      'To share this sensitive information, we need to confirm it’s really you.',
    descriptionEmail:
      'An email will be sent to the address linked to your store',
    completeMessage: 'We’ve sent an email to',
    completeMessageCode: 'Use this code to reveal your secret phrase: ',
  },
  createContractForm: {
    title: 'Create contract',
    contractName: {
      title: 'Contract name',
      description:
        'A permanent name for your contract that appears on Etherscan and marketplaces',
      errorMessage: 'Contract name is required',
    },
    contractSymbol: {
      title: 'Contract symbol',
      description: 'Maximum 6 characters. This cannot be changed later',
      errorMessage: 'Contract symbol is required',
    },
    description: {
      title: 'Description',
      description:
        'A few lines describing your contract to help collectors and others understand how you use it.',
      errorMessage: 'Description is required',
    },
    chain: {
      title: 'Blockchain',
      errorMessage: 'Blockchain is required',
    },
    placeHolders: {
      name: 'e.g. My contract',
      symbol: 'e.g. MYC',
    },
    helpText: {
      name: 'A permanent name for your contract that appears on Etherscan and marketplaces',
      symbol: 'Maximum 6 characters. This cannot be changed later',
      description:
        'Add a few lines to remember how you how you use the contract.',
    },
    manifold: {
      title: 'Use your Manifold contract',
      body: 'Import a Manifold contract on Verisart to use in your store',
      button: 'Import contract',
      link: 'Learn more',
    },
    errors: {
      badContractName:
        'Invalid input. Please ensure the contract name contains only letters (a-zA-Z), numbers (0-9), underscores (_), commas (,), hyphens (-), and spaces only',
      badContractSymbol:
        'Invalid input. Please ensure your entry contains only letters (A-Z, a-z) and numbers (0-9) only',
      descriptionTooLong:
        'Invalid input. Description can be at most 1000 characters',
      nameTooLong: 'Invalid input. Name can be at most 32 characters',
      symbolTooLong: 'Invalid input. Symbol can be at most 6 characters',
      errorTitle: `Sorry, we couldn't save your changes`,
      generalError: 'There was an problem creating the contract',
      notEnoughGas:
        "There aren't enough funds in your merchant wallet. Top up and try again. Learn how to top up ",
      helpCenter: 'here.',
      quotaExceeded: 'Your account plan has run out of usage credits',
    },
    toast: {
      createSuccess: 'Contract created',
      editSuccess: 'Contract updated',
      createError: 'Error creating contract',
      editError: 'Error updating contract',
    },
    modal: {
      heading: 'Deploy contract',
      warningTitle: 'This blockchain action is permanent',
      warningBulletOne: (...args: string[]) =>
        `On Ethereum, you need funds in your wallet`, // stripe 2 - (approx. $${args[0]})
      warningBulletTwo:
        'Once the action completes, you can’t update the information',
      warningBulletThree: 'Other blockchains are free with your plan',
    },
    successBanner: {
      title: 'Contract created successfully',
      message:
        'Your custom contract was registered on chain. Don’t forget to Set up your collection on OpenSea with your branding and royalty details.',
      link: 'How to guide',
      linkHref: 'https://help.verisart.com/en/',
    },
    editBanner: {
      title: 'Contract updated successfully',
      message: 'Your contract details were updated',
    },
  },
  royaltySplitForm: {
    toast: {
      createSuccess: 'Royalty details saved',
      editSuccess: 'Royalty details updated',
      createError: 'Error saving royalty details',
      editError: 'Error updating royalty details',
    },
  },
  createRoyaltySplitForm: {
    errors: {
      generalError: "Sorry, we couldn't save your royalty details",
      notEnoughGas:
        "There aren't enough funds in your merchant wallet. Top up the wallet and try again.",
    },
  },
  metafields: {
    public_file_url: 'Additional file',
    file_url: 'Collector reward file',
    artist_id: 'Creator',
    title: 'Title',
    description: 'Description',
    object_type: 'Object type',
    production_year: 'Production year',
    width: 'Width',
    height: 'Height',
    depth: 'Depth',
    dimension_unit: 'Dimension unit',
    blockchain: 'Blockchain',
    contract_address: 'Contract address',
    nft_royalty_address: 'Royalty address',
    edition_type: 'Edition type',
    edition_number: 'Edition number',
    edition_volume: 'Edition volume',
    ap_volume: 'AP volume',
    edition_note: 'Edition note',
    ap_number: 'AP number',
    auto_number_editions: 'Auto numbered editions',
    qr_linkable: 'Secure QR code',
    display_image_url: 'Display image',
    traits: 'Traits',
    custodial_minting_wallet: 'Merchant minting wallet',
    inventory_number: 'Inventory number',
    current_location: 'Current location',
    production_location: 'Production location',
    image_url: 'Image url',
    customization_id: 'Customized branding',
    note: 'Note',
    medium: 'Medium',
    tags: 'Tags',
    token_id_existing: 'Existing NFT',
    nft_title: 'NFT title',
    nft_description: 'NFT description',
    nft_image_url: 'NFT image url',
    nft_display_image_url: 'NFT display image',
  },
  productForm: {
    toasts: {
      success: 'Product saved',
    },
    fileDeleted: 'File deleted and saved',
    fileUploadedSuccessMessage: 'File uploaded and saved',
    importedNFT: {
      status: {
        title: 'Imported NFT',
        sold: 'Sold',
        ready: 'Ready to sell',
        cantTransfer: 'Action required',
      },
      modal: {
        title: 'Get ready to sell',
        primaryAction: 'Continue',
        secondaryAction: 'Cancel',
        text: 'For purchases to be fulfilled, imported NFTs must be owned or managed by your store’s wallet.',
        firstChoice: 'I own this NFT',
        firstHelpText: 'Transfer or grant permission yourself',
        secondChoice: 'Someone else owns this NFT',
        secondHelpText: 'Send transfer instructions to the owner',
      },
      secondaryModal: {
        action: 'Done',
        owningText:
          'Transfer the NFT to your store’s wallet address. When the transfer is complete it’s ready to sell.',
        wallet: 'Wallet address',
        owningHelpText:
          'You can also grant permission instead of transferring.',
        learnMore: 'Learn more',
        representingText:
          'Ask the NFT owner to transfer it to your store, Compose your own message or copy and edit the example below.',
        email: 'Email example',
        copy: 'Copy',
        emailContent: (arg: string) =>
          `Dear X,\n\nAs discussed, here are the details about the NFT sale.\n\nWe will list the NFT for sale on our store at XX.com\n\nIn order to sell it, you need to transfer it to our store’s wallet at:\n\n${arg}`,
        emailHelp: 'You can also grant permission instead of transferring.',
      },
    },
    print: {
      title1: 'This certificate was created as part of the',
      title2: 'Print',
      title3: 'service',
      subtitle: 'Some information cannot be edited.',
    },
    productInfoHelp:
      'Certificate of Authenticity will be generated once item is sold.',
    tags: {
      label: 'Tags appear as properties on your NFT ',
      helpText: 'Only letters and numbers',
    },
    title: 'About this product',
    assetRendererTitle: 'Media file',
    drop: 'Drop',
    uploadButtomText: 'Add files',

    dropzoneNFTImagesText: 'jpg, png, gif (max 40MB)',
    dropzoneNFTAssetsText:
      'jpg, png, gif (max 40MB), mp4, glb, gltf (max 300MB)',
    dropzoneCOAPresentationalText:
      'jpg, png, gif (max 40MB), mp4, mov, wmv, webm, glb, gltf (max 300MB)',
    dropzoneCollectorRewardText:
      'Maximum image size 40MB. All other file types 300MB. Supported file types: jpg, png, gif, mp4, mov, wmv, pdf, csv, txt, docx, doc, mp4, xlsx, xls, mp3, zip, glb, gltf, usdz, json, vrm, fbx',
    message: 'We need additional permissions to guarantee the best experience',
    upgrade: 'Upgrade Permissions',
    upgradePermission: {
      title: 'Update Permissions',
      message:
        'The latest version of the Verisart App includes new features. Update your permissions to take full advantage of them.',
      button: 'Update',
    },
    collectorRewards: {
      dropZoneTitle: 'Add files',
      dropRewards: 'Drop rewards',
      coaSubtitle: 'Additional private files for the certificate holder',
      nftSubtitle: 'Unlockable content for the owner of the product',
      dropTitle: 'Unlockable rewards',
      productTitle: 'Additional files',
      label: 'Label',
      addLabel: 'add label here',
      default: 'the default',
    },
    delete: {
      nftDrop: {
        title: 'Remove from drop',
        modalTitle: 'Are you sure?',
        confirmationMessage:
          'Remove from drop? This will not delete the product from your store.',
        confirmationButton: 'Remove from drop',
        successMessage: 'Removed from drop',
      },
      nftLegacy: {
        title: 'Turn off NFT',
        modalTitle: 'Are you sure?',
        confirmationMessage:
          'Turn off this NFT? This will not delete the product from your store.',
        confirmationButton: 'Turn off',
        successMessage: 'NFT turned off',
      },
      certificate: {
        title: 'Turn off certification',
        modalTitle: 'Are you sure?',
        confirmationMessage:
          'Turn off certification for this product? This will not delete the product from your store.',
        confirmationButton: 'Turn off',
        successMessage: 'Certificate turned off',
      },
      cancel: 'Cancel',
    },
    contractAddress: 'Contract',
    royaltyAddress: 'Royalties',
    tokenId: 'Token ID',
  },
  creatorStatus: {
    authorized: 'Authorized',
    pending: 'Pending',
  },
  productType: {
    title: 'Choose a product type',
    COA: {
      label: 'Certificate of Authenticity (COA)',
      value: 'CERTIFICATE',
    },
    NFT: {
      label: 'NFT - includes COA',
      value: 'NFT',
    },
  },
  breadcrumbs: {
    products: 'Products',
  },
  buttonText: {
    loading: 'Loading',
    save: 'Save',
  },
  plans: {
    BASIC: 'Basic',
    PREMIUM: 'Legacy Premium',
    BUSINESS: 'Legacy Business',
    FREE: 'Free',
    INDIVIDUAL_STARTER: 'Legacy Individual Starter',
    INDIVIDUAL_PREMIUM: 'Legacy Individual Premium',
    INDIVIDUAL_PRO: 'Legacy Individual Pro',
    BUSINESS_STARTER: 'Legacy Business Starter',
    BUSINESS_PREMIUM: 'Legacy Business Premium',
    BUSINESS_PRO: 'Legacy Business Pro',
    GROW: 'Growth',
    PRO: 'Pro',
    PAY_AS_YOU_GO: 'Pay as you sell',
  },
  applyForMinting: {
    title: 'Apply to mint and sell NFTs',
    firstSection:
      'If you use Shopify Payments, you will need to complete an application form. Based on your responses, Shopify will approve your store for selling, gifting or testing.',
    secondSection:
      'If you don’t use Shopify Payments, no restrictions apply. You can use alternative payment providers such as Paypal for card payments and DePay for crypto payments.',
    learnMore: 'Learn more',
    cancel: 'Cancel',
    next: 'Next',
  },
  editSelectorModal: {
    title: 'Edit in Verisart',
    subtitle: 'What should this product create?',
    cancel: 'Cancel',
    next: 'Next',
    new_nftButtonLabel: {
      title: 'NFT',
      subtitle: 'Add this product to a drop or create a new drop',
    },
    certificateButtonLabel: {
      title: 'Certificate of Authenticity',
      subtitle: 'Assign a creator to this product to issue a COA',
    },
    tokengateButtonLabel: {
      title: 'Tokengate',
      subtitle: 'Give tokenholders exclusive access to this product',
    },
  },
  productListItem: {
    edit: 'Edit',
  },
  productActionRequired: 'Action required',
  editionLockedBanner: {
    title: 'Edition locked',
    paragraph1Cert:
      'You have already sold at least one item for this product so the edition information is locked.',
    paragraph1NFT:
      'You have already sold at least one item for this product so the edition information is locked for this NFT contract.',
    paragraph2:
      'You can reset the edition information to change these values for future purchases.',
    nextEdition: 'The next edition number which will be created is:',
    resetEditionLink: 'Reset edition information',
  },
  resetEditionModal: {
    title: 'Reset edition information for product',
    content: 'Reset edition',
    cancel: 'Cancel',
    text: "This will reset the edition information for this product. It won't affect already generated items but will allow you to change these settings for future purchases. Do you want to continue?",
  },
};

const placeholderRegex = /({[\d|\w]+})/;

export const formatString = (
  key: string,
  ...values: Array<React.ReactChild>
): React.ReactNodeArray | string => {
  let hasObject = false;
  const result = (get(strings, key) || '')
    .split(placeholderRegex)
    .filter((textPart: string) => !!textPart)
    .map((textPart: string, index: number) => {
      if (textPart.match(placeholderRegex)) {
        const matchedKey = parseInt(textPart.slice(1, -1), 10);
        let valueForPlaceholder: React.ReactChild = values[matchedKey];

        if (React.isValidElement(valueForPlaceholder)) {
          hasObject = true;
          valueForPlaceholder = {
            ...valueForPlaceholder,
            key: index.toString(),
          };
          return React.Children.toArray(valueForPlaceholder);
        }

        return valueForPlaceholder;
      }
      return textPart;
    });

  if (hasObject) return result;
  return result.join('');
};

export const parameters = (
  key: string,
  ...values: React.ReactNode[]
): string => {
  const paramFunc = get(strings, key);
  if (!paramFunc) {
    const error = `[MISSING PARAMETERS: '${key}']`;
    // eslint-disable-next-line no-console
    console.error(error);
    return error;
  }
  if (typeof paramFunc !== 'function') {
    const error = `[WRONG PARAMETERS TYPE: '${key}']`;
    // eslint-disable-next-line no-console
    console.error(error);
    return error;
  }
  return paramFunc(...values);
};

export const translate = (key: string, defaultValue?: string): string => {
  const result = get(strings, key, defaultValue);
  if (!result) {
    const error = `[MISSING TRANSLATE: '${key}']`;
    // eslint-disable-next-line no-console
    console.error(error);
    return error;
  }
  if (typeof result !== 'string') {
    const error = `[WRONG TRANSLATE TYPE: '${key}']`;
    // eslint-disable-next-line no-console
    console.error(error);
    return error;
  }
  return result;
};

export default translate;
