import React, { useCallback, useEffect, useState } from 'react';
import {
  Banner,
  Button,
  Card,
  Icon,
  Modal,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import { StoreStatusMajor } from '@shopify/polaris-icons';
import SetPlan from 'components/SetPlan';
import { Pricing } from 'components/Pricing/index';
import t, { parameters as p } from 'lib/translation';
import { UseSubscriptionDetailsResponse } from '../../hooks/useSubscriptionDetails';
import useChangePlan from '../../hooks/useChangePlan';
import { PlanType } from '../../utils/plans';
import PageLayout from 'components/PageLayout';
import { Banners } from 'types/Banners';
import useCancelSubscription from '../../hooks/useCancelSubscription';
import withSaveToast from 'hooks/withSaveToast';
import { useIntercom } from 'react-use-intercom';
import { useOpenWindowOnVerisartDotCom } from '../../utils/mainWebsiteLogin';
import { useAppBridge } from 'containers/AppBridge';

interface PricingPageProps {
  data: UseSubscriptionDetailsResponse;
}

const PricingPage: React.FC<PricingPageProps> = ({ data }) => {
  const app = useAppBridge();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  const [legacyWarningModalOpen, setLegacyWarningModalOpen] =
    useState<PlanType | null>(null);

  const { mutateAsync: cancelSubscription } = useCancelSubscription();
  const [cancelFlow, setCancelFlow] = useState<
    'NOT_STARTED' | 'LOADING' | 'FINISHED'
  >('NOT_STARTED');

  const planExpired = data.subscriptionDetails.planType === PlanType.FREE;
  const currentPlanType = planExpired
    ? undefined
    : data.subscriptionDetails.planType;

  const { show } = useIntercom();

  const isStripeSubscriber = data?.subscriptionDetails?.isStripeSubscriber;
  const { mutateAsync: changePlan, isLoading: isChangePlanLoading } =
    useChangePlan();

  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();

  const handleCancelSubscription = async () => {
    setCancelFlow('LOADING');
    if (isStripeSubscriber) {
      await openWindowOnVerisartDotCom('/application/billing');
    } else {
      await cancelSubscription();
      setIsCancelling(false);
      setCancelModalOpen(false);
    }
    setCancelFlow('FINISHED');
  };

  const isLegacy =
    currentPlanType &&
    currentPlanType !== PlanType.FREE &&
    currentPlanType !== PlanType.GROW &&
    currentPlanType !== PlanType.PRO &&
    currentPlanType !== PlanType.PAY_AS_YOU_GO;

  const handleChangePlan = useCallback(
    async (plan: string) => {
      if (isStripeSubscriber) {
        await openWindowOnVerisartDotCom('/application/billing');
      } else {
        if (!isLegacy) {
          await changePlan({ planType: plan, redirectPath: 'plan' });
        } else {
          setLegacyWarningModalOpen(plan as PlanType);
        }
      }
    },
    [isStripeSubscriber, openWindowOnVerisartDotCom, isLegacy, changePlan]
  );

  const handleManagePlan = useCallback(() => {
    if (isStripeSubscriber) {
      openWindowOnVerisartDotCom('/application/billing');
    }
  }, [openWindowOnVerisartDotCom, isStripeSubscriber]);

  const planType = 'plan';
  const currentLevel = data.subscriptionDetails.planType;
  useEffect(() => {
    const lastSeenLevel = window.sessionStorage.getItem(planType) ?? undefined;
    if (
      lastSeenLevel !== undefined &&
      currentLevel !== undefined &&
      lastSeenLevel !== currentLevel
    ) {
      withSaveToast(
        async () => {
          return '';
        },
        app.showToast,
        {
          successMessage: t('settings.plans.planUpdated'),
        }
      );
    }
    if (currentLevel !== undefined) {
      window.sessionStorage.setItem(planType, currentLevel);
    }
  }, [currentLevel, app]);

  return (
    <>
      <PageLayout displayedBanners={[Banners.LEGACY_PLAN]}>
        <SetPlan />
        <Stack vertical>
          {cancelFlow === 'FINISHED' && (
            <Stack.Item>
              <Banner
                status={'success'}
                title={t('pricing.cancelBanner.title')}
              >
                {t('pricing.cancelBanner.message')}
              </Banner>
            </Stack.Item>
          )}
          {planExpired && cancelFlow !== 'FINISHED' && (
            <Stack.Item>
              <Banner status="warning" title={t('pricing.expiredBanner.title')}>
                {t('pricing.expiredBanner.message')}
                <Button plain onClick={show}>
                  {t('pricing.expiredBanner.messageLink')}
                </Button>
              </Banner>
            </Stack.Item>
          )}
          {!planExpired && cancelFlow !== 'FINISHED' && (
            <Stack.Item>
              <Card>
                <Card.Section>
                  <Stack spacing="loose" alignment="center">
                    <Stack.Item>
                      <Icon source={StoreStatusMajor} />
                    </Stack.Item>
                    <Stack.Item fill>
                      <TextStyle variation="strong">
                        {p(
                          'pricing.currentPlan',
                          t(`plans.${currentPlanType}`)
                        )}
                      </TextStyle>
                    </Stack.Item>
                    <Stack.Item>
                      <Button
                        loading={cancelFlow === 'LOADING'}
                        onClick={() => setCancelModalOpen(true)}
                      >
                        {t('pricing.cancelPlan.button')}
                      </Button>
                    </Stack.Item>
                  </Stack>
                </Card.Section>
              </Card>
            </Stack.Item>
          )}

          <Stack.Item fill>
            <Stack>
              <Stack.Item fill>
                <Pricing
                  currentPlan={currentPlanType}
                  onManagePlan={
                    isStripeSubscriber ? handleManagePlan : undefined
                  }
                  onPlanSelected={handleChangePlan}
                  selectionDisabled={isChangePlanLoading}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </PageLayout>
      {cancelModalOpen && (
        <Modal
          primaryAction={{
            loading: isCancelling,
            destructive: true,
            onAction: async () => {
              setIsCancelling(true);
              handleCancelSubscription();
            },
            content: t('pricing.cancelPlan.confirmCancel'),
          }}
          title={t('pricing.cancelPlan.cancelModalTitle')}
          open={cancelModalOpen}
          onClose={() => setCancelModalOpen(false)}
        >
          <Modal.Section>
            <p>{t('pricing.cancelPlan.cancelModalBody')}</p>
          </Modal.Section>
        </Modal>
      )}
      {legacyWarningModalOpen && (
        <Modal
          primaryAction={{
            destructive: false,
            onAction: async () => {
              const plan = legacyWarningModalOpen;
              setLegacyWarningModalOpen(null);
              await changePlan({ planType: plan, redirectPath: 'plan' });
            },
            content: 'Change Plan',
          }}
          title={'Changing Plan'}
          open={!!legacyWarningModalOpen}
          onClose={() => setLegacyWarningModalOpen(null)}
        >
          <Modal.Section>
            <p>
              You are on a legacy plan. Changing to a new plan is irreversible.
            </p>
          </Modal.Section>
        </Modal>
      )}
    </>
  );
};

export default PricingPage;
