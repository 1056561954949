export enum PlanType {
  PREMIUM = 'PREMIUM',
  BUSINESS = 'BUSINESS',
  FREE = 'FREE',
  INDIVIDUAL_STARTER = 'INDIVIDUAL_STARTER',
  INDIVIDUAL_PREMIUM = 'INDIVIDUAL_PREMIUM',
  INDIVIDUAL_PRO = 'INDIVIDUAL_PRO',
  BUSINESS_STARTER = 'BUSINESS_STARTER',
  BUSINESS_PREMIUM = 'BUSINESS_PREMIUM',
  BUSINESS_PRO = 'BUSINESS_PRO',
  GROW = 'GROW',
  PRO = 'PRO',
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
}
