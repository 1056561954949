import { AxiosResponse } from 'axios';
import { MetafieldFormValues } from 'pages/ProductForm/ProductFormShow';
import { useQuery } from 'react-query';
import { RelationshipDataDetails } from 'types/Artist';
import API from 'utils/api';
import { CapabilityResponse } from './useGetCapabilities';
import { Blockchain } from '../types/Blockchain';
import {
  PayeeWithName,
  useGetRoyaltyAddresses,
} from './useGetRoyaltyAddresses';
import useGetContracts from './useGetContracts';

export interface MetafieldFormVariant {
  id: string;
  name: string;
  thumbnail: string;
  status: 'READY' | 'NEEDS_REVIEW';
}

interface MetafieldFormProduct {
  id: string;
  name: string;
  thumbnail: string;
  status: 'ACTIVE' | 'DRAFT';
  variants: Array<MetafieldFormVariant>;
  mainMedia: string | null;
  isFreeProduct: boolean | null;
}

export interface MetafieldValue {
  inheritedValue: string[];
  value: string[];
  issue: { [key in number]: string } | null;
  finalValue: string[];
}

const MetafieldsOther = [
  'is_this_edition',
  'product_trigger_level',
  'status',
] as const;

const MetafieldArrays = [
  'file_label',
  'file_url',
  'public_file_url',
  'public_file_label',
] as const;

const MetafieldSingles = [
  'artist_id',
  'title',
  'description',
  'object_type',
  'production_year',
  'width',
  'height',
  'depth',
  'dimension_unit',
  'blockchain',
  'contract_address',
  'nft_royalty_address',
  'edition_type',
  'edition_number',
  'edition_volume',
  'ap_volume',
  'edition_note',
  'ap_number',
  'auto_number_editions',
  'qr_linkable',
  'display_image_url',
  'traits',
  'custodial_minting_wallet',
  'inventory_number',
  'current_location',
  'production_location',
  'image_url',
  'customization_id',
  'note',
  'medium',
  'tags',
  'token_id_existing',
  'art_blocks_project_id',
  'highlight_id',
  'nft_title',
  'nft_description',
  'nft_image_url',
  'nft_display_image_url',
] as const;

export type MetafieldsType =
  | typeof MetafieldArrays[number]
  | typeof MetafieldSingles[number];

/**
 * The metafields that are returned by the API
 */
const ReadableMetaFields = [
  ...MetafieldSingles,
  ...MetafieldsOther,
  ...MetafieldArrays,
];

/**
 * The metafields that we will submit back
 */
export const SubmittableMetaFields = [
  // Note that file_url and file_label are excluded as these are submitted directly by the drop zone
  ...MetafieldSingles,
  ...MetafieldsOther,
] as const;

type ReadableMetaField = typeof ReadableMetaFields[number];

export type MetafieldToValue = {
  [metafield in ReadableMetaField]: MetafieldValue;
};
export type MetafieldToArrayValue = {
  [metafield in keyof MetafieldFormValues]: { value: string[] };
};

export interface ProductFormValues {
  triggerLevel: TriggerLevel;
  product: MetafieldFormProduct;
  metafields: MetafieldToValue;
  editionLocked: boolean;
  autoNumberEditionNext: number | null;
  print: string | null;
}

export type TriggerLevel = 'OFF' | 'CERTIFICATE' | 'NFT' | 'BUNDLE';

export interface ManagedArtists {
  relatedArtist: Array<RelationshipDataDetails>;
}

export interface PaymentSplitterContractRenderable {
  id: string;
  accountId: string;
  walletId: string;
  name: string;
  contractAddress: string;
  blockchain: Blockchain;
  payees: PayeeWithName[];
  ratios: number[];
}

const fetchProductFormValues = async (
  productId: string,
  isVariant: boolean
) => {
  return (
    await API().get(
      `shopify/${
        isVariant ? 'variants' : 'products'
      }/${productId}/metafieldFormValues`
    )
  ).data;
};

const fetchManagedArtists = async () => {
  return (await API().get('/managedArtist/artist')).data;
};

const fetchCapabilities = async () => {
  return (await API().get('/plan')).data;
};

export const fetchRoyaltyAddress = async (
  paymentSplitterContractId: string
) => {
  return (
    await API().get('/shopify/royaltyAddress/' + paymentSplitterContractId)
  ).data;
};

const patchAsync = async (
  isVariant: boolean,
  productId: string,
  data: {
    metafields: { [metafield: string]: { value: string[] } };
    productTriggerLevel: string | null;
  }
): Promise<AxiosResponse<ProductFormValues>> => {
  const response = await API().patch(
    `/shopify/${
      isVariant ? 'variants' : 'products'
    }/${productId}/metafieldFormValues`,
    data
  );
  return response;
};

const useProductFormValues = (
  productId: string,
  isVariant: boolean,
  enabled: boolean = true
) => {
  const productFormValues = useQuery<ProductFormValues>(
    ['productMetadata', isVariant, productId],
    () => fetchProductFormValues(productId, isVariant),
    {
      /** TODO: A better fix here would be to clear the cache when we update  */
      cacheTime: 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  );
  const relatedArtists = useQuery<ManagedArtists>(
    'relatedArtists',
    () => fetchManagedArtists(),
    {
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      enabled,
    }
  );
  const capabilities = useQuery<CapabilityResponse>(
    'plan',
    () => fetchCapabilities(),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  );
  const contracts = useGetContracts('VALID');
  const royaltyAddresses = useGetRoyaltyAddresses(false, false);
  const patch = (data: {
    metafields: { [metafield: string]: { value: string[] } };
    productTriggerLevel: string | null;
  }): Promise<AxiosResponse<ProductFormValues>> => {
    return patchAsync(isVariant, productId, data);
  };

  /**
   * This variable (when true) should allow for us to safely cast to non-nullable types
   */
  const isLoading =
    productFormValues.isLoading ||
    relatedArtists.isLoading ||
    capabilities.isLoading ||
    contracts.isLoading ||
    royaltyAddresses.isLoading;

  return {
    productFormValues,
    relatedArtists,
    capabilities,
    contracts,
    royaltyAddresses,
    isLoading,
    patch,
  };
};

export default useProductFormValues;
