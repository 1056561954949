import {
  Modal,
  ResourceList,
  ResourceItem,
  Avatar,
  Stack,
  Spinner,
  TextField,
  Checkbox,
} from '@shopify/polaris';
import React, { useCallback, useMemo, useState } from 'react';
import { useGetProducts } from '../../hooks/useGetProducts';
import { ProductMinimal } from '../../hooks/useProduct';
import { debounce } from 'lodash';

interface ProductPickerProps {
  onCancel: () => void;
  onSelection:
    | ((items: number[]) => Promise<void>)
    | ((items: number[]) => void);
  initialSelectionIds: string[];
  initialQuery?: string;
  selectMultiple?: boolean;
}

const DEBOUNCE_TIME = 200;

export const ProductPicker: React.FC<ProductPickerProps> = ({
  onCancel,
  onSelection,
  initialSelectionIds,
  initialQuery,
  selectMultiple = true,
}) => {
  const [inputValue, setInputValue] = useState(initialQuery ?? '');
  const [query, setQuery] = useState(inputValue);
  const {
    products,
    getMoreProducts,
    moreAvailable,
    loading: productsLoading,
  } = useGetProducts(query);

  const [selectedProducts, setSelectedProducts] =
    useState<string[]>(initialSelectionIds);

  const debounceSetQuery = useMemo(
    () =>
      debounce((input: string) => {
        setQuery(input);
      }, DEBOUNCE_TIME),
    []
  );

  const handleInputChange = useCallback(
    (queryString: string) => {
      setInputValue(queryString);
      debounceSetQuery(queryString);
    },
    [debounceSetQuery]
  );

  return (
    <Modal
      open={true}
      title={'Select products'}
      onClose={onCancel}
      primaryAction={{
        content: 'Update products',
        onAction: async () => {
          onSelection(selectedProducts.map((id) => Number(id)));
          onCancel();
        },
      }}
      onScrolledToBottom={async () => {
        if (!moreAvailable) return;
        if (productsLoading) return;
        await getMoreProducts();
      }}
      secondaryActions={[{ content: 'Cancel', onAction: onCancel }]}
    >
      <Modal.Section>
        <Stack vertical spacing={'loose'}>
          <TextField
            placeholder={'Search products'}
            autoFocus
            label={'Query'}
            autoComplete={'off'}
            value={inputValue}
            onChange={handleInputChange}
          />
          <ResourceList
            showHeader={false}
            items={products}
            renderItem={(item: ProductMinimal) => {
              const disabled =
                !selectMultiple &&
                !selectedProducts
                  .map((id) => id.toString())
                  .includes(item.id.toString()) &&
                selectedProducts.length > 0;
              const checked = selectedProducts
                .map((id) => id.toString())
                .includes(item.id.toString());
              return (
                <ResourceItem
                  verticalAlignment={'center'}
                  key={item.id.toString()}
                  id={item.id.toString()}
                  onClick={() => {
                    if (disabled) return;
                    setSelectedProducts((data) =>
                      checked
                        ? data.filter(
                            (id) => id.toString() !== item.id.toString()
                          )
                        : [...data, item.id.toString()]
                    );
                  }}
                  media={<Avatar source={item.thumbnailUrl ?? ''} />}
                >
                  <Stack>
                    <Stack.Item fill>{item.title}</Stack.Item>
                    <Checkbox
                      disabled={disabled}
                      label={undefined}
                      checked={checked}
                    />
                  </Stack>
                </ResourceItem>
              );
            }}
          />
        </Stack>
        <Stack distribution={'center'}>
          {productsLoading && <Spinner size={'large'} />}
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
