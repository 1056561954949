import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Page,
  Card,
  Heading,
  Stack,
  TextStyle,
  FormLayout,
  Banner,
  Layout,
  Button,
  Modal,
} from '@shopify/polaris';
import DropNFTProducts from './DropNFTProducts';
import TextInput from 'components/forms/TextInput';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntercom } from 'react-use-intercom';
import { IntercomEvents } from 'interfaces/IntercomEvents';
import useContextualSaveBar from 'hooks/useContextualSaveBar';
import withSaveToast from 'hooks/withSaveToast';
import ControllerSelectInput from 'components/forms/SelectInput';
import useGetContracts, { Contract } from 'hooks/useGetContracts';
import { formatContractNameFromObject } from 'utils/stringFormatting';
import DropRoyalties from './DropRoyalties/DropRoyalties';
import DropCollectorRewards from './DropCollectorRewards';
import DropTokengate from './DropTokengate';
import HowToPanel from 'components/HowToPanel';
import useToast from 'hooks/useToast';
import { Blockchain } from 'types/Blockchain';
import generateUuid from '@shopify/app-bridge/actions/uuid';
import useCreateDrop, { CollectorRewardFile } from 'hooks/useCreateDrop';
import useGetTokenGates from 'hooks/useGetTokenGates';

import t, { parameters as p } from 'lib/translation';
import styles from './DropForm.module.scss';
import assert from 'assert';
import { TokenGate } from '../../hooks/useGetTokenGate';
import RoyaltySplitModalCreateLoader from '../../pages/RoyaltySplit/RoyaltySplitModalCreateLoader';
import LoadingPage from 'auth/LoadingPage';
import useMainnetMintingEnabled from '../../hooks/useMainnetMintingEnabled';
import CreateContractsFormDialog from '../../pages/CreateContractsFormDialog/CreateContractsFormDialog';
import TokenGateFormDialog from '../TokenGateForm/TokenGateFormDialog';
import { GetDropsResponse } from '../../hooks/useGetDrops';
import useGetDrop from '../../hooks/useGetDrop';
import useUpdateDrop from '../../hooks/useUpdateDrop';
import { useHistory, Prompt } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useDeleteDrop from '../../hooks/useDeleteDrop';
import { useHistoryGoBack } from '../../hooks/useHistoryGoBack';
import DropPayGasFee from './DropPayGasFee/DropPayGasFee';
import { MintMode } from 'hooks/useCreateDrop';
import {
  warnContractNotDeployed,
  warnRoyaltiesNotDeployed,
} from '../../pages/DropPage/DropPageList';
import { getPlatform, useAppBridge } from 'containers/AppBridge';
import { validPositiveNumber, validBytes32 } from '../../utils/validation';
import { isEnvironmentTest } from '../../constants';
import useShop from 'hooks/useShop';
import { PlanType } from 'utils/plans';

export type DropsFormValues = {
  dropId: string;
  dropName: string;
  chain: Blockchain | null;
  contract: string | null;
  products: number[];
  royalties: string | null;
  rewardFiles: CollectorRewardFile[];
  mintMode: MintMode;
  projectId: string | null;
  highlightId: string | null;
};

export type DropsFormProps = {
  /**
   * Set when you're editing an item
   */
  editId?: string;
};

const DropForm: React.FC<DropsFormProps> = ({ editId }) => {
  const { data: contractData } = useGetContracts('ALL');
  const { data: tokenGateData } = useGetTokenGates();
  const { mintingAvailable, isLoading: mintingAvailableLoading } =
    useMainnetMintingEnabled();
  const { data: editDrop } = useGetDrop(editId ?? '', {
    enabled: !!editId,
  });

  const isPremintedNFT = editDrop?.drop.externalAddress ? true : false;

  const history = useHistory();
  const queryClient = useQueryClient();

  const onCreated = useCallback(
    async (created: GetDropsResponse) => {
      queryClient.setQueryData(['drop', created.drop.id], created); // Avoids the loading flicker when we redirect to the edit page
      history.push(`/drops/edit/${created.drop.id}`);
    },
    [history, queryClient]
  );

  if (
    !contractData ||
    !tokenGateData ||
    mintingAvailableLoading ||
    (!editDrop && editId)
  ) {
    return <LoadingPage />;
  }

  return (
    <DropFormUI
      onTestMode={!mintingAvailable}
      contracts={contractData.contracts}
      tokenGateData={tokenGateData}
      edit={editDrop ?? null}
      onCreated={onCreated}
      disabled={isPremintedNFT}
    />
  );
};

type DropsFormUIProps = {
  onTestMode: boolean;
  contracts: Contract[];
  tokenGateData: TokenGate[];
  disabled?: boolean;
  onCreated: (drop: GetDropsResponse) => void;

  /** Set for edit mode */
  edit: GetDropsResponse | null;
};

function getDefaultValues(
  drop: GetDropsResponse | null,
  newID?: string | null
): DropsFormValues {
  const editDrop = drop?.drop;
  const dropId = editDrop?.id ?? newID;

  assert(dropId);

  return {
    dropId: dropId,
    dropName: editDrop?.name ?? '',
    chain: drop?.contract?.blockchain ?? drop?.drop?.externalBlockchain ?? null,
    contract: editDrop?.contractId ?? null,
    products: editDrop?.productsIds ?? [],
    royalties: editDrop?.paymentSplitterContractId ?? null,
    rewardFiles: editDrop?.collectorRewardFiles ?? [],
    mintMode: editDrop?.mintMode ?? MintMode.CUSTODIAL_MINTING,
    projectId: editDrop?.artBlocksProjectId?.toString() ?? null,
    highlightId: editDrop?.highlightId?.toString() ?? null,
  };
}

const DropFormUI: React.FC<DropsFormUIProps> = ({
  onTestMode,
  contracts,
  tokenGateData,
  onCreated,
  edit,
  disabled,
}) => {
  const { trackEvent } = useIntercom();
  const [contractsModal, setContractsModal] = useState(false);
  const [tokengateModal, setTokengateModal] = useState(false);
  const [royaltyModal, setRoyaltyModal] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);

  const showToast = useToast((state) => state.showToast);
  const history = useHistory();
  const historyGoBack = useHistoryGoBack();

  const { mutateAsync: createDrop } = useCreateDrop();
  const { mutateAsync: updateDrop } = useUpdateDrop();
  const { mutateAsync: deleteDrop } = useDeleteDrop();
  const shopData = useShop();

  // We have to pick an ID up front for creates. This is so that when we're uploading reward files, they
  // can be tagged with the drop even though it doesn't exist yet.
  const newID = useMemo(() => generateUuid(), []);

  const methods = useForm<DropsFormValues>({
    defaultValues: getDefaultValues(edit, newID),
  });

  const {
    handleSubmit,
    formState: { isDirty },
    watch,
    setValue,
    reset,
  } = methods;

  const app = useAppBridge();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onSave = async () => {
    await handleSubmit(submitForm, (e) => {
      throw Error(); // This is just needed so that we throw an exception from onSave so that useContextualSaveBar() will not hide itself
    })();
  };

  // Set the newly created royalty split back as a value
  const onRoyaltySplitCreated = useCallback(
    (royaltyId: string) => {
      setValue('royalties', royaltyId, { shouldDirty: true });
      setRoyaltyModal(false);
    },
    [setValue]
  );

  // Set the newly created contract back as a value
  const onContractCreated = useCallback(
    (contractId: string) => {
      setValue('contract', contractId, { shouldDirty: true });
      setContractsModal(false);
    },
    [setValue]
  );

  const submitForm = async (values: DropsFormValues) => {
    setApiError(null);

    const {
      dropId,
      contract,
      dropName,
      products,
      royalties,
      rewardFiles,
      mintMode,
      projectId,
      highlightId,
    } = values;

    try {
      const request = {
        id: dropId,
        name: dropName,
        contractId: contract,
        productIds: products,
        paymentSplitterContractId: royalties,
        collectorRewardFiles: rewardFiles,
        mintMode: mintMode,
        artBlocksProjectId:
          projectId !== undefined && projectId !== null
            ? parseInt(projectId)
            : null,
        highlightId: highlightId,
      };

      let drop: GetDropsResponse;
      if (!edit) {
        drop = await createDrop(request);
        trackEvent(IntercomEvents.dropCreated);
        onCreated(drop);
      } else {
        drop = await updateDrop(request);
      }

      // Needed to persuade react-hook-form that the new data is not stale
      reset(getDefaultValues(drop));

      withSaveToast(null, app.showToast, {
        successMessage: edit
          ? t('dropForm.toast.editSuccess')
          : t('dropForm.toast.createSuccess'),
      });
    } catch (error) {
      setApiError(
        error?.response?.data?.message ??
          error?.message ??
          'Something went wrong'
      );
      throw error;
    } finally {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useContextualSaveBar<DropsFormValues>(
    methods,
    onSave,
    tokengateModal || contractsModal || royaltyModal
  );

  const selectedChain = watch('chain');

  // Used for import NFTs as that's the only time when the external address and external blockchain are set at drop level
  const [editExternalAddress, setEditExternalAddress] = useState<
    string | undefined
  >();
  const [editExternalBlockchain, setEditExternalBlockchain] = useState<
    Blockchain | undefined
  >();

  useEffect(() => {
    // Jointly set or don't set at all
    if (edit?.drop?.externalAddress && edit?.drop?.externalBlockchain) {
      setEditExternalAddress(edit?.drop?.externalAddress);
      setEditExternalBlockchain(edit?.drop?.externalBlockchain);
    }
  }, [edit]);

  const contract = watch('contract');
  const dropId = watch('dropId');
  const contractObject = useMemo(
    () => contracts.find((c) => c.id === contract),
    [contract, contracts]
  );

  const matchingContracts = useMemo(
    () =>
      contracts
        .filter((c) => c.blockchain === selectedChain)
        .map((c) => ({
          value: c.id,
          label: formatContractNameFromObject(c),
        })),
    [contracts, selectedChain]
  );

  useEffect(() => {
    if (contractObject && !contractObject.supportsSignableMinting) {
      setValue('mintMode', MintMode.CUSTODIAL_MINTING, { shouldDirty: true });
    }
  }, [setValue, contractObject]);

  useEffect(() => {
    if (contractObject && contractObject.type !== 'ART_BLOCKS') {
      setValue('projectId', null, { shouldDirty: true });
    }
  }, [setValue, contractObject]);

  useEffect(() => {
    if (contractObject && contractObject.type !== 'HIGHLIGHT') {
      setValue('highlightId', null, { shouldDirty: true });
    }
  }, [setValue, contractObject]);

  const onBlockchainChanged = useCallback(() => {
    setValue('contract', null);
    setValue('royalties', null);
  }, [setValue]);

  const undeployedContract =
    edit?.contract && warnContractNotDeployed(edit.contract);
  const undeployedPaymentSplitter =
    edit?.paymentSplitter && warnRoyaltiesNotDeployed(edit.paymentSplitter);

  const title = edit ? t('dropForm.titleEdit') : t('dropForm.titleCreate');

  const isFreeGasChain =
    selectedChain === Blockchain.BASE_MAINNET ||
    selectedChain === Blockchain.POLYGON;

  return (
    <div className={styles['mb-8']}>
      {edit && (
        <Prompt message={t('dropForm.navigateAwayPrompt')} when={isDirty} />
      )}
      <Page
        breadcrumbs={[
          {
            content: title,
            onAction: () => historyGoBack('/drops'),
          },
        ]}
        title={title}
      >
        <Layout>
          {edit?.drop.hasIssue && (
            <Layout.Section>
              <Banner
                title={t('dropForm.productsWithIssuesBanner.title')}
                status={'warning'}
              >
                <p>{t('dropForm.productsWithIssuesBanner.body')}</p>
              </Banner>
            </Layout.Section>
          )}
          {(undeployedContract || undeployedPaymentSplitter) && (
            <Layout.Section>
              <Banner
                title={t('dropForm.contractNotDeployedBanner.title')}
                status={'warning'}
                secondaryAction={{
                  content: t('dropForm.contractNotDeployedBanner.learnMore'),
                  url: 'https://help.verisart.com/en/articles/8201205-how-to-handle-contracts-stuck-in-pending',
                }}
              >
                <Stack vertical>
                  <Stack.Item>
                    {t('dropForm.contractNotDeployedBanner.body')}
                  </Stack.Item>
                  <Stack.Item>
                    {t('dropForm.contractNotDeployedBanner.itemsAffected')}
                    <ul>
                      {undeployedContract && (
                        <li>
                          {p(
                            'dropForm.contractNotDeployedBanner.contract',
                            edit?.contract?.name
                          )}
                        </li>
                      )}
                      {undeployedPaymentSplitter && (
                        <li>
                          {p(
                            'dropForm.contractNotDeployedBanner.paymentSplitter',
                            edit?.paymentSplitter?.name
                          )}
                        </li>
                      )}
                    </ul>
                  </Stack.Item>
                </Stack>
              </Banner>
            </Layout.Section>
          )}
          {apiError && (
            <Layout.Section>
              <Banner title={t('dropForm.errors.noSave')} status={'critical'}>
                <p>{apiError ?? t('tokenGateForm.error.noSaveChanges')}</p>
              </Banner>
            </Layout.Section>
          )}
          <Layout.Section>
            <FormProvider {...methods}>
              <Card>
                <Card.Section>
                  <FormLayout>
                    <div className={styles['mb-8']}>
                      <TextInput
                        name="dropName"
                        label={t('dropForm.dropName')}
                        required={t('dropForm.dropNameError')}
                        maxLength={32}
                        error={methods.formState.errors?.dropName?.message}
                      />
                    </div>
                    <TextStyle variation="strong">
                      {t('dropForm.chainInfo').toUpperCase()}
                    </TextStyle>
                    {disabled ||
                      (contractObject &&
                        !contractObject.supportsSignableMinting && (
                          <Banner>
                            {t(
                              'dropForm.gasFeeCustomization.unsupportedContract'
                            )}
                          </Banner>
                        ))}
                    <ControllerSelectInput
                      label={t(
                        'royaltySplitPage.chainSection.selectInput.label'
                      )}
                      name="chain"
                      options={[
                        {
                          value: Blockchain.BASE_MAINNET,
                          label: t(`blockchain.${Blockchain.BASE_MAINNET}`),
                          disabled:
                            onTestMode ||
                            shopData.data?.shop.planType ===
                              PlanType.PAY_AS_YOU_GO,
                        },
                        {
                          value: Blockchain.ETHEREUM,
                          label: t(`blockchain.${Blockchain.ETHEREUM}`),
                          disabled: onTestMode,
                        },
                        {
                          value: Blockchain.ETHEREUM_SEPOLIA,
                          label: t(`blockchain.${Blockchain.ETHEREUM_SEPOLIA}`),
                        },
                        {
                          value: Blockchain.POLYGON,
                          label: t(`blockchain.${Blockchain.POLYGON}`),
                          disabled:
                            onTestMode ||
                            shopData.data?.shop.planType ===
                              PlanType.PAY_AS_YOU_GO,
                        },
                        ...(isEnvironmentTest()
                          ? [
                              {
                                value: Blockchain.FAKE_ETHEREUM_ON_SEPOLIA,
                                label: t(
                                  `blockchain.${Blockchain.FAKE_ETHEREUM_ON_SEPOLIA}`
                                ),
                              },
                              {
                                value: Blockchain.FAKE_BASE_ON_BASE_SEPOLIA,
                                label: t(
                                  `blockchain.${Blockchain.FAKE_BASE_ON_BASE_SEPOLIA}`
                                ),
                              },
                            ]
                          : []),
                      ]}
                      required={t('createContractForm.chain.errorMessage')}
                      error={methods.formState.errors?.chain?.message}
                      customOnChange={onBlockchainChanged}
                      disabled={disabled}
                    />
                    <div className={styles['heading-link']}>
                      <Stack alignment="fill">
                        <Stack.Item fill>
                          <TextStyle>{t('dropForm.contract')}</TextStyle>
                        </Stack.Item>
                        <Stack.Item>
                          <Button
                            onClick={() => {
                              if (!selectedChain) {
                                showToast(t('dropForm.toast.missingChain'));
                              } else {
                                setContractsModal(!contractsModal);
                              }
                            }}
                            plain
                            disabled={disabled}
                          >
                            {t('dropForm.createContract')}
                          </Button>
                        </Stack.Item>
                      </Stack>
                    </div>
                    <ControllerSelectInput
                      label=""
                      name="contract"
                      options={matchingContracts}
                      required={
                        edit?.drop.externalAddress ? '' : 'Contract is required'
                      }
                      error={methods.formState.errors?.contract?.message}
                      disabled={selectedChain === null || disabled}
                    />
                    {contractObject?.type === 'ART_BLOCKS' && (
                      <TextInput
                        name="projectId"
                        label={t('dropForm.projectId')}
                        required={t('dropForm.projectIdError')}
                        validator={validPositiveNumber}
                        maxLength={32}
                        error={methods.formState.errors?.projectId?.message}
                      />
                    )}
                    {contractObject?.type === 'HIGHLIGHT' && (
                      <TextInput
                        name="highlightId"
                        label={t('dropForm.highlightId')}
                        required={t('dropForm.highlightError')}
                        validator={validBytes32}
                        maxLength={66}
                        error={methods.formState.errors?.highlightId?.message}
                      />
                    )}
                    {!isFreeGasChain && (
                      <div className={styles['heading-link']}>
                        <DropPayGasFee
                          disabled={
                            disabled || !contractObject?.supportsSignableMinting
                          }
                        />
                      </div>
                    )}

                    {isFreeGasChain && (
                      <TextStyle variation="subdued">
                        {t('settingsSection.customGwei.tooltip')}
                      </TextStyle>
                    )}
                  </FormLayout>
                </Card.Section>
              </Card>
              <div className={styles['heading-spacing']}>
                <Heading>{t('dropForm.nftProducts.title')}</Heading>
                <TextStyle variation="subdued">
                  {t('dropForm.nftProducts.subtitle')}
                </TextStyle>
              </div>
              <DropNFTProducts dropId={edit?.drop?.id} disabled={disabled} />
              <div className={styles['heading-spacing']}>
                <Heading>{t('dropForm.royalties.title')}</Heading>
                <TextStyle variation="subdued">
                  {t('dropForm.royalties.subtitle')}
                </TextStyle>
              </div>
              <DropRoyalties
                disabled={
                  contractObject?.type === 'ART_BLOCKS' ||
                  contractObject?.type === 'HIGHLIGHT' ||
                  disabled ||
                  false
                }
                createNewSplitter={() => setRoyaltyModal(true)}
              />
              <div className={styles['heading-spacing']}>
                <Heading>{t('dropForm.enhance.title')}</Heading>
              </div>
              <DropCollectorRewards dropId={dropId} />
              {getPlatform() !== 'Woo' && (
                <DropTokengate
                  tokenGateData={tokenGateData}
                  disabled={false}
                  contracts={contracts}
                  showModal={() => {
                    if (!selectedChain && !editExternalBlockchain) {
                      showToast(t('dropForm.toast.missingChain'));
                    } else if (!contractObject && !editExternalAddress) {
                      showToast(t('dropForm.toast.selectTokenGateError'));
                    } else if (
                      !contractObject?.address &&
                      !editExternalAddress
                    ) {
                      showToast(t('dropForm.toast.tokengateError'));
                    } else {
                      setTokengateModal(true);
                    }
                  }}
                />
              )}
            </FormProvider>
            {edit && (
              <div className={styles['mt-4']}>
                <Button
                  onClick={() => setDeleteModalOpen(true)}
                  destructive
                  outline
                >
                  Delete
                </Button>
              </div>
            )}
          </Layout.Section>
          <Layout.Section secondary>
            <HowToPanel
              title={t('dropForm.helpPanel.title')}
              link={t('dropForm.helpPanel.link')}
              linkUrl={
                'https://www.notion.so/Verisart-Shopify-App-Partner-Portal-72db9b1d093746a8a8dd792455809e35?pvs=21'
              }
              instructions={[
                {
                  title: t('dropForm.helpPanel.titleOne'),
                  subtitle: t('dropForm.helpPanel.subtitleOne'),
                },
                {
                  title: t('dropForm.helpPanel.titleTwo'),
                  subtitle: t('dropForm.helpPanel.subtitleTwo'),
                },
                {
                  title: t('dropForm.helpPanel.titleThree'),
                  subtitle: t('dropForm.helpPanel.subtitleThree'),
                },
                {
                  title: t('dropForm.helpPanel.titleFour'),
                  subtitle: t('dropForm.helpPanel.subtitleFour'),
                },
              ]}
            />
          </Layout.Section>
        </Layout>
      </Page>
      {deleteModalOpen && (
        <Modal
          primaryAction={{
            loading: isDeleting,
            destructive: true,
            onAction: async () => {
              setIsDeleting(true);
              assert(deleteDrop, 'deleteDrop is undefined');
              assert(
                edit?.drop?.id,
                'edit is undefined but a delete was attempted'
              );
              await deleteDrop(edit.drop.id);
              history.push('/drops', { replace: true });
            },
            content: t('dropForm.deleteCta'),
          }}
          secondaryActions={[
            { content: 'Cancel', onAction: () => setDeleteModalOpen(false) },
          ]}
          title={t('dropForm.deleteModalTitle')}
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
        >
          <Modal.Section>
            <p>{t('dropForm.deleteModalBody')}</p>
          </Modal.Section>
        </Modal>
      )}
      {/* Modals. Note these need to be outside of this form as many of these have their own forms */}
      {contractsModal && (
        <CreateContractsFormDialog
          lockBlockchain={selectedChain!}
          showModal={contractsModal}
          setShowModal={setContractsModal}
          onSuccess={onContractCreated}
        />
      )}
      {tokengateModal &&
        (editExternalAddress && editExternalBlockchain ? (
          <TokenGateFormDialog
            formType="Create"
            lockBlockchain={editExternalBlockchain}
            lockContract={editExternalAddress}
            showModal={tokengateModal}
            setShowModal={setTokengateModal}
            onSuccess={() => setTokengateModal(false)}
          />
        ) : (
          <TokenGateFormDialog
            formType="Create"
            lockBlockchain={selectedChain!}
            lockContract={contractObject!.address!}
            showModal={tokengateModal}
            setShowModal={setTokengateModal}
            onSuccess={() => setTokengateModal(false)}
          />
        ))}
      {royaltyModal && (
        <RoyaltySplitModalCreateLoader
          lockBlockchain={selectedChain!}
          showModal={royaltyModal}
          setShowModal={setRoyaltyModal}
          onRoyaltySplitCreated={onRoyaltySplitCreated}
        />
      )}
    </div>
  );
};

export default DropForm;
