import API from './api';
import axios from 'axios';

type UploadType =
  | 'MAIN_ASSET'
  | 'DISPLAY_IMAGE'
  | 'COLLECTOR_REWARD'
  | 'NFT_ASSET'
  | 'NFT_DISPLAY_IMAGE';

/**
 * Uploads a file to S3 and changes/adds/appends the associated product metafield
 */
export const uploadProductFile = async (
  productId: string,
  uploadType: UploadType,
  file: File
): Promise<string> => {
  const url = await uploadFile(productId, null, null, uploadType, file);
  return associateFileWithMetafield(
    productId,
    null,
    uploadType,
    url,
    file.name
  );
};

/**
 * Uploads a file to S3 and changes/adds/appends the associated variant metafield
 */
export const uploadVariantFile = async (
  variantId: string,
  uploadType: UploadType,
  file: File
): Promise<string> => {
  const url = await uploadFile(null, variantId, null, uploadType, file);
  return associateFileWithMetafield(
    null,
    variantId,
    uploadType,
    url,
    file.name
  );
};

const associateFileWithMetafield = async (
  productId: string | null,
  variantId: string | null,
  uploadType: UploadType,
  url: string,
  fileLabel?: string
) => {
  if (productId === null && variantId === null)
    throw new Error('Must provide either productId or variantId');

  const response = await API().post(
    `/shop/${productId ? 'products' : 'variants'}/associateFile`,
    {
      productId,
      variantId,
      uploadType,
      url,
      fileLabel: fileLabel ?? null,
    }
  );
  return response.data.url;
};

const uploadFile = async (
  productId: string | null,
  variantId: string | null,
  dropId: string | null,
  uploadType: UploadType,
  file: File
): Promise<string> => {
  if (xor(productId === null, variantId === null, dropId === null)) {
    if (dropId !== null && uploadType !== 'COLLECTOR_REWARD')
      throw new Error('Can only upload collector rewards to drops');
    const uploadUrl = (
      await API().post('/shop/upload', {
        productId,
        variantId,
        dropId,
        uploadType,
        mediaType: file.type,
        fileSize: file.size,
      })
    ).data.url;

    await axios.put(uploadUrl, file, {
      headers: {
        'X-Amz-Acl': 'public-read',
        'Content-Type': file.type,
      },
    });
    const url = new URL(uploadUrl);
    return url.origin + url.pathname; // Clean up the url
  } else {
    throw new Error(
      'Must provide either productId, variantId or dropId, but not all'
    );
  }
};

/**
 * Uploads a file to S3
 * Note that the presigned url is obtained from the backend
 * Special params such as dropId (as opposed to productId) are passed to obtain a presigned url for a drop file
 * Note that this function ONLY uploads the file to S3, it does not modify the files associated with the drop
 */
export const uploadDropFileToS3 = async (
  dropId: string,
  file: File
): Promise<string> => {
  return uploadFile(null, null, dropId, 'COLLECTOR_REWARD', file);
};

const xor = (a: boolean, b: boolean, c: boolean) => {
  return (a && b && !c) || (a && !b && c) || (!a && b && c);
};
