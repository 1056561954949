import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { OnboardingModalContent, openAccountLink } from './index';
import {
  Button,
  FormLayout,
  Heading,
  Link,
  Modal,
  Stack,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import styles from './OnboardingPage.module.scss';
import { translate as t } from 'lib/translation';
import RadioButtonInput from '../../components/forms/RadioButtonInput';
import TextInput from '../../components/forms/TextInput';
import LessThan from '../../assets/svgComponents/LessThan';
import { validYearOfBirth } from '../../utils/validYearOfBirth';
import { getPlatform, getShopDomain, useAppBridge } from 'containers/AppBridge';
import { PlanType } from '../../utils/plans';

export type UserType = 'new' | 'existing';

const validEmailAddress = (value: string | null): boolean => {
  if (!value) return false;
  const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

  return emailRegex.test(value);
};

export type OnboardingStep =
  | 'neworexisting'
  | 'creatordetails'
  | 'shopemail'
  | 'verisartlogin'
  | 'selectplantype';

interface OnboardingModalProps {
  steps: OnboardingStep[];
  pushStep: (step: OnboardingStep) => void;
  stepBack: () => void;
  onClose: () => void;
  completeOnboarding: (values: OnboardingModalFormValues) => void;
  completeOnboardingLoading: boolean;
  mode: 'onboardingShopify' | 'onboardingVerisart';
}

export type CreatorType = 'individual' | 'business';
export interface OnboardingModalFormValues {
  userType: UserType | null;
  planType: PlanType | null;
  creatorFirstName: string;
  creatorLastName: string;
  creatorYear: string;
  shopEmailAddress: string | null;
  creatorType: CreatorType;
}

export const OnboardingModal: React.FC<OnboardingModalProps> = ({
  steps,
  pushStep,
  onClose,
  completeOnboarding,
  completeOnboardingLoading,
  stepBack,
  mode,
}) => {
  const appBridge = useAppBridge();
  const shopDomain = getShopDomain();

  const methods = useFormContext<OnboardingModalFormValues>();
  const userType = methods.watch('userType');
  const planType = methods.watch('planType');
  const shopEmailAddress = methods.watch('shopEmailAddress');
  const creatorType = methods.watch('creatorType');
  const creatorFirstName = methods.watch('creatorFirstName');
  const creatorLastName = methods.watch('creatorLastName');
  const creatorYear = methods.watch('creatorYear');
  const isShopify = getPlatform() === 'Shopify';

  const step: OnboardingStep | undefined =
    steps.length === 0 ? undefined : steps[0];

  const backButton = (onClick?: () => void) =>
    steps.length > 0 ? (
      <Button
        icon={LessThan}
        onClick={() => {
          onClick?.();
          stepBack();
        }}
        disabled={completeOnboardingLoading}
        key="back"
        accessibilityLabel="Back"
      />
    ) : undefined;

  const clearCreatorFields = useCallback(() => {
    methods.setValue('creatorFirstName', '');
    methods.setValue('creatorLastName', '');
    methods.setValue('creatorYear', '');
  }, [methods]);

  const clearShopEmailFields = useCallback(() => {
    methods.setValue('shopEmailAddress', '');
  }, [methods]);

  return (
    <Modal titleHidden large title="" open={steps.length > 0} onClose={onClose}>
      <div className={styles.container}>
        {step === 'neworexisting' && (
          <OnboardingModalContent
            title={t('onboarding.newOrExisting.title')}
            actions={[
              <Stack alignment="center">
                <Stack.Item>
                  {isShopify
                    ? t('onboarding.shopifyPagination.first')
                    : t('onboarding.pagination.first')}
                </Stack.Item>
              </Stack>,
              <Button
                key="nextNewOrExisting"
                primary
                disabled={!userType}
                onClick={() => {
                  pushStep(
                    userType === 'existing'
                      ? 'verisartlogin'
                      : isShopify
                      ? 'selectplantype'
                      : 'shopemail'
                  );
                }}
              >
                {t('onboarding.action.next')}
              </Button>,
            ]}
          >
            <Stack vertical spacing="tight">
              <Stack vertical spacing="baseTight">
                <RadioButtonInput
                  label={
                    <Heading element={'h5'}>
                      {t('onboarding.newOrExisting.option.new')}
                    </Heading>
                  }
                  value={'new'}
                  name={'userType'}
                />
                <RadioButtonInput
                  label={
                    <span className={styles.radioOverride}>
                      <Heading element={'h5'}>
                        {t('onboarding.newOrExisting.option.existing')}
                      </Heading>
                    </span>
                  }
                  value={'existing'}
                  name={'userType'}
                />
              </Stack>
              <TextContainer>
                <TextStyle variation="subdued">
                  {t('onboarding.newOrExisting.notice')}
                </TextStyle>{' '}
                <Link url="https://verisart.com/terms" external>
                  {t('onboarding.newOrExisting.readTerms')}
                </Link>
              </TextContainer>
            </Stack>
          </OnboardingModalContent>
        )}
        {step === 'shopemail' && (
          <OnboardingModalContent
            title={'Set up your email'}
            actions={[
              <Stack alignment="center">
                {backButton(clearShopEmailFields)}
                <Stack.Item>{t('onboarding.pagination.second')}</Stack.Item>
              </Stack>,
              <Button
                key="shopemail"
                primary
                disabled={shopEmailAddress === ''}
                onClick={() => {
                  if (!validEmailAddress(shopEmailAddress)) {
                    methods.setError('shopEmailAddress', {
                      type: 'invalid',
                      message: 'Please enter a valid email address',
                    });
                  } else {
                    pushStep('selectplantype');
                  }
                }}
              >
                {t('onboarding.action.next')}
              </Button>,
            ]}
          >
            <Stack vertical>
              <Stack vertical spacing={'loose'}>
                <TextContainer>
                  <TextStyle>
                    {
                      'Please enter your email address. We will use this to send you important notifications, order confirmations, and occasional updates about our services. '
                    }
                  </TextStyle>
                </TextContainer>
                <FormLayout>
                  <TextInput
                    label={'Email Address'}
                    name={'shopEmailAddress'}
                    error={methods.formState.errors.shopEmailAddress?.message}
                    customOnChange={() => {
                      methods.clearErrors('shopEmailAddress');
                    }}
                  />
                </FormLayout>
              </Stack>
            </Stack>
          </OnboardingModalContent>
        )}
        {step === 'verisartlogin' && (
          <OnboardingModalContent
            title={t('onboarding.verisartLogin.title')}
            actions={[
              <Stack alignment="center">
                {backButton()}
                <Stack.Item>{t('onboarding.pagination.third')}</Stack.Item>
              </Stack>,
              <Button
                key="nextVerisartLogin"
                primary
                onClick={() => openAccountLink(appBridge, shopDomain)}
              >
                {t('onboarding.verisartLogin.action.next')}
              </Button>,
            ]}
          >
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle variation="subdued">
                {t('onboarding.verisartLogin.message')}
              </TextStyle>
              <img
                alt="A preview of the Verisart login"
                src="/images/signup.png"
                height="192"
                width="192"
              />
            </Stack>
          </OnboardingModalContent>
        )}
        {step === 'selectplantype' && (
          <OnboardingModalContent
            title={
              mode === 'onboardingVerisart'
                ? 'What best describes you?'
                : t('onboarding.selectPlanType.title')
            }
            actions={[
              <Stack alignment="center">
                {backButton()}
                <Stack.Item>
                  {isShopify
                    ? t('onboarding.shopifyPagination.second')
                    : t('onboarding.pagination.third')}
                </Stack.Item>
              </Stack>,
              <Button
                key="nextSelectPlanType"
                primary
                disabled={planType === null}
                onClick={() => {
                  pushStep('creatordetails');
                }}
              >
                {t('onboarding.action.next')}
              </Button>,
            ]}
          >
            <Stack vertical spacing="baseTight">
              <RadioButtonInput
                label={
                  <Heading element={'h5'}>
                    {t('onboarding.selectPlanType.payAsYouGo.label')}
                  </Heading>
                }
                name={'planType'}
                value={PlanType.PAY_AS_YOU_GO}
                helpText={
                  mode === 'onboardingVerisart'
                    ? ''
                    : t('onboarding.selectPlanType.payAsYouGo.help')
                }
              />
              <RadioButtonInput
                label={
                  <Heading element={'h5'}>
                    {t('onboarding.selectPlanType.grow.label')}
                  </Heading>
                }
                name={'planType'}
                value={PlanType.GROW}
                helpText={
                  mode === 'onboardingVerisart'
                    ? ''
                    : t('onboarding.selectPlanType.grow.help')
                }
              />
              <RadioButtonInput
                label={
                  <Heading element={'h5'}>
                    {t('onboarding.selectPlanType.pro.label')}
                  </Heading>
                }
                name={'planType'}
                value={PlanType.PRO}
                helpText={
                  mode === 'onboardingVerisart'
                    ? ''
                    : t('onboarding.selectPlanType.pro.help')
                }
              />
              {mode === 'onboardingShopify' && (
                <TextContainer>
                  <TextStyle variation="subdued">
                    <span className={styles.planNotice}>
                      {t('onboarding.selectPlanType.notice')}
                    </span>
                  </TextStyle>
                </TextContainer>
              )}
            </Stack>
          </OnboardingModalContent>
        )}
        {step === 'creatordetails' && (
          <OnboardingModalContent
            title={
              planType === PlanType.GROW
                ? t('onboarding.creatorDetails.title')
                : t('onboarding.creatorDetails.businessTitle')
            }
            actions={[
              <Stack alignment="center">
                {backButton(clearCreatorFields)}
                <Stack.Item>
                  {isShopify
                    ? t('onboarding.shopifyPagination.third')
                    : t('onboarding.pagination.fourth')}
                </Stack.Item>
              </Stack>,
              <Button
                primary
                disabled={
                  creatorType === 'individual'
                    ? creatorFirstName === '' ||
                      creatorLastName === '' ||
                      creatorYear === ''
                    : creatorFirstName === '' || creatorYear === ''
                }
                loading={completeOnboardingLoading}
                onClick={async () => {
                  await methods.handleSubmit(completeOnboarding)();
                }}
              >
                {t('onboarding.action.next')}
              </Button>,
            ]}
          >
            <Stack vertical>
              <Stack vertical spacing={'loose'}>
                <Stack vertical spacing="baseTight">
                  <RadioButtonInput
                    label={
                      <Heading element={'h5'}>
                        I'm an individual creator
                      </Heading>
                    }
                    value={'individual'}
                    name={'creatorType'}
                  />
                  <RadioButtonInput
                    label={
                      <span className={styles.radioOverride}>
                        <Heading element={'h5'}>
                          I represent a business or brand
                        </Heading>
                      </span>
                    }
                    value={'business'}
                    name={'creatorType'}
                  />
                </Stack>

                <TextContainer>
                  <TextStyle>
                    {t('onboarding.creatorDetails.creatorDescription')}
                  </TextStyle>{' '}
                  <Link
                    url="http://help.verisart.com/en/articles/5388239-adding-verified-creators-on-shopify"
                    external
                  >
                    {t('onboarding.creatorDetails.link')}
                  </Link>
                </TextContainer>
                {creatorType === 'individual' && (
                  <>
                    <FormLayout>
                      <FormLayout.Group>
                        <TextInput
                          label={t(
                            'onboarding.creatorDetails.creatorFirstNameLabel'
                          )}
                          name={'creatorFirstName'}
                          error={
                            methods.formState.errors.creatorFirstName?.message
                          }
                          customOnChange={() =>
                            methods.clearErrors('creatorFirstName')
                          }
                        />
                        <TextInput
                          label={t(
                            'onboarding.creatorDetails.creatorLastNameLabel'
                          )}
                          name={'creatorLastName'}
                          error={
                            methods.formState.errors.creatorLastName?.message
                          }
                          customOnChange={() =>
                            methods.clearErrors('creatorLastName')
                          }
                        />
                      </FormLayout.Group>
                      <TextInput
                        label={t(
                          'onboarding.creatorDetails.creatorYear.individual'
                        )}
                        name={'creatorYear'}
                        validator={validYearOfBirth(creatorType)}
                        error={methods.formState.errors.creatorYear?.message}
                        customOnChange={() =>
                          methods.clearErrors('creatorYear')
                        }
                      />
                    </FormLayout>
                  </>
                )}
                {creatorType === 'business' && (
                  <>
                    <TextInput
                      label={t('onboarding.creatorDetails.brandName')}
                      name={'creatorFirstName'}
                      error={methods.formState.errors.creatorFirstName?.message}
                      customOnChange={() =>
                        methods.clearErrors('creatorFirstName')
                      }
                    />
                    <div className={styles.foundedYear}>
                      <TextInput
                        label={t(
                          'onboarding.creatorDetails.creatorYear.business'
                        )}
                        name={'creatorYear'}
                        validator={validYearOfBirth(creatorType)}
                        error={methods.formState.errors.creatorYear?.message}
                        customOnChange={(abc) =>
                          methods.clearErrors('creatorYear')
                        }
                      />
                    </div>
                  </>
                )}
              </Stack>
            </Stack>
          </OnboardingModalContent>
        )}
      </div>
    </Modal>
  );
};
