import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  Badge,
  Banner,
  BannerStatus,
  Button,
  FormLayout,
  Layout,
  Link,
  Page,
  PageActions,
  RadioButton,
  SelectGroup,
  SelectOption,
  Stack,
  TextStyle,
  Thumbnail,
  Heading,
} from '@shopify/polaris';
import ProductInfo from './ProductInfo';
import {
  ManagedArtists,
  MetafieldsType,
  MetafieldToValue,
  ProductFormValues,
  TriggerLevel,
} from 'hooks/useProductFormValues';
import t, { parameters as p } from 'lib/translation';
import { CapabilityResponse } from 'hooks/useGetCapabilities';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { retrieveErrorMessage } from 'utils/validationMessaging';
import QRPanel from './QRPanel';
import MetafieldErrorBanner from './MetafieldErrorBanner';
import VariantsLinkSection from 'components/VariantsLinkSection';
import CardElement from './CardElement';
import AssetRenderer from './AssetRenderer';
import {
  NonPhysicalObjects,
  ObjectType,
  ObjectTypeOptions,
} from 'components/ObjectTypeSelector/objectTypeOptions';
import { ArtistAttributes } from 'types/Artist';
import Tags from './Tags';
import { NeedsRefresh } from 'hooks/useNeedPermissionRefresh';
import { isImageFile, isVideoFile, isModelFile } from 'utils/validation';
import { useParams } from 'react-router';
import TextInput from 'components/forms/TextInput';
import SelectInput from 'components/forms/SelectInput';
import useMainnetMintingEnabled from 'hooks/useMainnetMintingEnabled';
import GlobalBanners from 'components/GlobalBanners';
import { Banners } from 'types/Banners';
import CollectorRewards from './collectorRewards/CollectorRewards';
import useGetDropProduct from '../../hooks/useGetDropProduct';
import { CollectorRewardFile } from 'hooks/useCreateDrop';
import { Blockchain, isMainnet } from '../../types/Blockchain';
import { useGetRoyaltyAddresses } from '../../hooks/useGetRoyaltyAddresses';
import useGetContracts from '../../hooks/useGetContracts';
import { useOpenWindowOnVerisartDotCom } from '../../utils/mainWebsiteLogin';
import { useHistoryGoBack } from '../../hooks/useHistoryGoBack';
import { useHistory, useLocation } from 'react-router-dom';
import { Toast } from '@shopify/polaris';
import ResetEditionModal from './ResetEditionModal';
import EditionLockedBanner from './EditionLockedBanner';
import { useAppBridge } from 'containers/AppBridge';
import CustomizationInfo from './CustomizationInfo';
import mixpanel from 'mixpanel-browser';
import { uploadProductFile } from 'utils/fileUpload';
import useHasAccountPurchasedSticker from 'hooks/useHasAccountPurchasedSticker';
import { getPlatform } from 'containers/AppBridge';

const toastDurationMs = 5000;

export interface ProductFormShowInterface {
  needsPermissionRefresh: NeedsRefresh;
  productFormValues: ProductFormValues;
  relatedArtistsData: ManagedArtists;
  capabilities: CapabilityResponse;
  onSubmit: (
    setIsSubmitting: (isSubmitting: boolean) => void,
    onSuccess: (submittedData?: MetafieldFormValues) => void,
    onFail: () => void,
    setError: (
      metafield: MetafieldsType,
      errorObject: { type: string; message: string }
    ) => void
  ) => (data: MetafieldFormValues) => void;
  onDeactivateProduct?: () => Promise<void>;
}

const ProductFormShow: React.FC<ProductFormShowInterface> = ({
  needsPermissionRefresh,
  productFormValues,
  relatedArtistsData,
  capabilities,
  onSubmit,
  onDeactivateProduct,
}) => {
  const isPrint = !!productFormValues.print;
  const app = useAppBridge();
  const history = useHistory();
  const location = useLocation();
  const platform = getPlatform();

  // Human readable errors per metafield
  const initialErrors: { [metafield in MetafieldsType]: string } = {} as {
    [metafield in MetafieldsType]: string;
  };

  // Original error codes per metafield
  const initialErrorCodes: { [metafield in MetafieldsType]: string } = {} as {
    [metafield in MetafieldsType]: string;
  };

  const initialValues: MetafieldFormValues = {} as MetafieldFormValues;
  const { defaultType } =
    useParams<{ defaultType: 'nft' | 'certificate' | undefined }>();

  const metafields = productFormValues.metafields;
  (Object.keys(metafields) as MetafieldsType[]).forEach((metafield) => {
    const value = metafields[metafield];
    if (value?.issue) {
      const arr = Object.keys(value.issue).sort(
        ([key1], [key2]) => parseInt(key1) - parseInt(key2)
      );
      initialErrorCodes[metafield] = value.issue[parseInt(arr[0])];
      initialErrors[metafield] = retrieveErrorMessage(
        initialErrorCodes[metafield]
      );
    }
    initialValues[metafield] =
      value?.value?.[0] === undefined
        ? null
        : value?.value !== undefined
        ? value.value[0]
        : null;
  });

  const editionLocked = productFormValues.editionLocked;

  const [productTriggerLevel, setProductTriggerLevel] = useState<TriggerLevel>(
    productFormValues.triggerLevel as TriggerLevel
  );

  const [collectorRewardAssets, setCollectorRewardAssets] = useState<
    CollectorRewardFile[]
  >(() => {
    if (!metafields?.file_url?.value) return [];

    const values: CollectorRewardFile[] = [];

    metafields?.file_url.value.forEach((el, index) => {
      let val = !metafields.file_label.value[index]
        ? t('productForm.collectorRewards.addLabel')
        : metafields.file_label.value[index];
      values.push({ fileURL: el, fileLabel: val });
    });

    return values;
  });

  const { data: dropData } = useGetDropProduct(
    productFormValues.product.id,
    defaultType === 'nft' || productTriggerLevel === 'NFT'
  );

  const { data: hasPurchasedSticker } = useHasAccountPurchasedSticker();

  const { data: royaltySplitsData } = useGetRoyaltyAddresses();
  const { data: contracts } = useGetContracts('ALL');

  const methods = useForm<MetafieldFormValues>({
    defaultValues: {
      ...initialValues,
      ...{
        qr_linkable: metafields.qr_linkable.value[0] ?? 'true',
        is_this_edition:
          metafields.edition_type.value?.[0] !== undefined &&
          isEditionType(metafields.edition_type.value?.[0])
            ? 'yes'
            : 'no',
        product_trigger_level:
          defaultType !== undefined && productTriggerLevel === 'OFF'
            ? (defaultType.toUpperCase() as 'NFT' | 'CERTIFICATE' | 'BUNDLE')
            : productTriggerLevel,
        status: productFormValues.product.status,
      },
    },
  });

  const artistId = methods.watch('artist_id');
  const showEdition = methods.watch('is_this_edition') === 'yes';
  const editionType = methods.watch('edition_type');
  const originalEditionType = metafields.edition_type.value?.[0];
  const editionTypeToShow =
    editionType != null && isEditionType(editionType)
      ? (editionType as EditionType)
      : null;
  const objectType = methods.watch('object_type');
  const isNft = useMemo(
    () => productTriggerLevel === 'NFT',
    [productTriggerLevel]
  );
  const isBundle = useMemo(
    () => productTriggerLevel === 'BUNDLE',
    [productTriggerLevel]
  );

  // These are no longer editable in the form - they're information only so we show the final, derived value
  // and now what is on the actual product
  // Note we use the metafields and not the Drop so we still support legacy metafield based NFTs
  const blockchain = metafields.blockchain.finalValue[0] as
    | Blockchain
    | undefined;
  const contractMetafield = metafields.contract_address.finalValue[0];
  const royaltyMetafield = metafields.nft_royalty_address.finalValue[0];
  const tokenIdExisting = metafields.token_id_existing.finalValue[0];
  const artBlocksProjectId = metafields.art_blocks_project_id.finalValue[0];
  const highlightId = metafields.highlight_id.finalValue[0];

  // Find the contract and payment splitter.
  // Note that in some cases the metafield might be an ID and not an address
  const contract = contractMetafield
    ? contracts?.contracts?.find(
        (it) => it.id === contractMetafield || it.address === contractMetafield
      )
    : null;

  const royaltySplitter = royaltyMetafield
    ? royaltySplitsData?.royaltyAddresses?.find(
        (it) =>
          it.id === royaltyMetafield ||
          it.contractAddress === royaltyMetafield ||
          (it.payees?.find((payee) => payee.address === royaltyMetafield) &&
            it.payees?.length === 1)
      )
    : null;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [bannerInfo, setBannerInfo] = useState<{
    title?: string;
    message: string;
    status?: BannerStatus;
    action?: { text: string; url: string };
  } | null>(
    needsPermissionRefresh.url !== null
      ? {
          title: t('productForm.upgradePermission.title'),
          message: t('productForm.upgradePermission.message'),
          status: 'warning',
          action: {
            text: t('productForm.upgradePermission.button'),
            url: `${needsPermissionRefresh.url}&redirect_uri=https://${window.location.hostname}/redirect`,
          },
        }
      : null
  );

  const { mintingAvailable, giftingEnabled, paidEnabled } =
    useMainnetMintingEnabled();

  const giftingWarning =
    mintingAvailable &&
    giftingEnabled &&
    !productFormValues.product.isFreeProduct &&
    !paidEnabled;

  const giftingModeWarningBanner = () => {
    if (giftingWarning) {
      return (
        <Layout.Section>
          <Banner title={t('info.giftingModeWarning.title')} status="warning">
            {t('info.giftingModeWarning.description')}{' '}
            <Link
              url="https://help.verisart.com/en/articles/6616531-what-does-test-mode-mean"
              external
            >
              {t('info.giftingModeWarning.link')}
            </Link>
          </Banner>
        </Layout.Section>
      );
    }
  };

  const imageURL = methods.watch('image_url');
  const mainAssetInherited = useMemo(() => {
    if (imageURL) {
      return imageURL === metafields?.image_url?.inheritedValue?.[0];
    } else {
      return (
        metafields?.image_url?.finalValue?.[0] ===
        metafields?.image_url?.inheritedValue?.[0]
      );
    }
  }, [
    imageURL,
    metafields?.image_url?.finalValue,
    metafields?.image_url?.inheritedValue,
  ]);

  const [mainAsset, setMainAsset] = useState<string | undefined>(() => {
    return (
      metafields?.image_url?.value?.[0] ??
      metafields?.image_url?.inheritedValue?.[0] ??
      (productFormValues.product.mainMedia == null
        ? undefined
        : productFormValues.product.mainMedia)
    );
  });

  const [nftImage, setNftImage] = useState<string | undefined>(() => {
    return (
      metafields?.nft_image_url?.value?.[0] ??
      metafields?.nft_image_url?.inheritedValue?.[0]
    );
  });

  const [imageUploadError, setImageUploadError] = useState<ReactNode | null>(
    null
  );
  const [nftImageUploadError, setNftImageUploadError] =
    useState<ReactNode | null>(null);
  const [displayImageError, setDisplayImageError] = useState<ReactNode | null>(
    null
  );
  const [nftDisplayImageError, setNftDisplayImageError] =
    useState<ReactNode | null>(null);

  const [successfulSubmittedData, setSuccessfulSubmittedData] =
    useState<MetafieldFormValues | null>(null);

  // The reset of the form must happen in the subsequent useEffect as per the docs - see https://react-hook-form.com/api/useform/reset#rules
  useEffect(() => {
    if (methods.formState.isSubmitSuccessful && successfulSubmittedData) {
      methods.reset(
        { ...successfulSubmittedData },
        { keepValues: true, keepSubmitCount: true }
      );
      setProductTriggerLevel(successfulSubmittedData.product_trigger_level);
    }
  }, [
    successfulSubmittedData,
    methods.formState.isSubmitSuccessful,
    methods.reset,
    methods,
  ]);
  /**
   * A displayAsset is string if there actually exists an url to use as display image
   * A display asset is undefined if there does NOT exist a display asset, but we would like to upload one
   * A display asset is null when it makes no sense to upload one
   */
  const [displayAsset, setDisplayAsset] = useState<string | undefined>(() =>
    mainAsset && (isVideoFile(mainAsset) || isModelFile(mainAsset))
      ? metafields?.display_image_url?.finalValue?.[0]
      : undefined
  );

  const [nftDisplayAsset, setNftDisplayAsset] = useState<string | undefined>(
    () =>
      nftImage && (isVideoFile(nftImage) || isModelFile(nftImage))
        ? metafields?.nft_display_image_url?.finalValue?.[0]
        : undefined
  );

  const currentRelationship = relatedArtistsData.relatedArtist.find(
    (artist) => artist.id === artistId
  );

  const deleteButtonTranslation =
    defaultType === 'nft' && dropData
      ? 'nftDrop'
      : defaultType === 'nft' && !dropData
      ? 'nftLegacy'
      : 'certificate';

  useEffect(() => {
    //set errors for metafields on the first render only
    (Object.keys(initialErrors) as MetafieldsType[]).forEach((metafield) => {
      methods.setError(metafield, {
        // We're overloading the `type` field with the error code. The docs of react-hook-form are a bit unclear
        // if this is OK or not, but it seems to work fine. (They let you set an arbitrary string)
        type: initialErrorCodes[metafield],
        message: initialErrors[metafield],
      });
    });
    // We only want to render the errors on the very first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [resetEditionModal, setResetEditionModal] = useState(false);

  const [unlinkModalOpen, setUnlinkModalOpen] = useState(false);
  const [isUnlinking, setUnlinking] = useState(false);
  const unlinkMessage = {
    title: t(`productForm.delete.${deleteButtonTranslation}.title`),
    modalTitle: t(`productForm.delete.${deleteButtonTranslation}.modalTitle`),
    confirmationMessage: t(
      `productForm.delete.${deleteButtonTranslation}.confirmationMessage`
    ),
    confirmationButton: t(
      `productForm.delete.${deleteButtonTranslation}.confirmationButton`
    ),
    successMessage: t(
      `productForm.delete.${deleteButtonTranslation}.successMessage`
    ),
  };

  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();

  const returnUrl = dropData ? `/drops/edit/${dropData.id}` : `/${defaultType}`;
  const historyGoBack = useHistoryGoBack();

  const existingNFTStatus =
    initialErrorCodes.token_id_existing === 'token_id_existing.nft_already_sold'
      ? 'already_sold'
      : initialErrorCodes.token_id_existing ===
        'token_id_existing.wallet_cant_transfer_existing'
      ? 'cant_transfer'
      : tokenIdExisting
      ? 'ready'
      : null;

  const isPredefinedDrop = !!(artBlocksProjectId || highlightId);
  const isExistingNFT = !!existingNFTStatus;
  const lockProductForm = isExistingNFT || isPredefinedDrop;

  const [importSuccessfull, setImportSuccessful] = useState<boolean>(false);
  const [dropTypeMessage, setDropTypeMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const search = new URLSearchParams(location.search);

    if (search.has('success')) {
      if (search.get('success') === 'true') {
        setImportSuccessful(true);
      }
      search.delete('success');
      location.search = search.toString();
      history.replace(location);
    }

    if (search.has('isExisting')) {
      if (search.get('isExisting') === 'true') {
        setDropTypeMessage(t('premintedNFTs.toasts.addedToExistingDrop'));
      } else if (search.get('isExisting') === 'false') {
        setDropTypeMessage(t('premintedNFTs.toasts.newDropCreated'));
      }
      search.delete('isExisting');
      location.search = search.toString();
      history.replace(location);
    }
  }, [history, location, location.search]);

  const handleOnSave = () => {
    methods.clearErrors();
    methods.handleSubmit(
      onSubmit(
        setIsSubmitting,
        (input?: MetafieldFormValues) => {
          if (input) {
            setSuccessfulSubmittedData(input);
          }
          if (
            metafields.qr_linkable.value[0] !== input?.qr_linkable &&
            input?.qr_linkable === 'true'
          ) {
            if (productTriggerLevel === 'NFT') {
              mixpanel.track('Product Additional Added', {
                platform: platform,
                Type: 'NFT',
                field: 'QR',
                'QR label added': true,
              });
            } else if (productTriggerLevel === 'CERTIFICATE') {
              mixpanel.track('Product Additional Added', {
                platform: platform,
                Type: 'Certificate',
                field: 'QR',
                'QR label added': true,
              });
            }
          }
          if (
            metafields.edition_type.value[0] &&
            input?.edition_type !== null
          ) {
            if (productTriggerLevel === 'NFT') {
              mixpanel.track('Product Additional Added', {
                platform: platform,
                Type: 'NFT',
                field: 'Edition Type',
                'Edition Type': input?.edition_type,
              });
            } else if (productTriggerLevel === 'CERTIFICATE') {
              mixpanel.track('Product Additional Added', {
                platform: platform,
                Type: 'Certificate',
                field: 'QR',
                'Edition Type': input?.edition_type,
              });
            }
          }
          if (metafields.traits === null && input?.traits !== null) {
            if (
              productTriggerLevel === 'NFT' ||
              productTriggerLevel === 'BUNDLE'
            ) {
              mixpanel.track('Product Additional Added', {
                platform: platform,
                Type: 'NFT',
                field: 'Traits',
              });
            }
          }
          app.showToast({
            message: t('productForm.toasts.success'),
            duration: toastDurationMs,
          });
        },
        () => {
          app.showToast({
            message: t('errors.productForm.toastFailure'),
            duration: toastDurationMs,
          });
        },
        methods.setError
      )
    )();
  };

  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  const saveButtonContents = {
    content: isSubmitting ? t('buttonText.loading') : t('buttonText.save'),
    disabled: isSubmitting,
    onAction: handleOnSave,
  };

  const artistError = methods.formState.errors.artist_id?.message;
  return (
    <div style={{ marginBottom: '2rem' }}>
      <FormProvider {...methods}>
        <Page
          breadcrumbs={[
            {
              content: t('breadcrumbs.products'),
              onAction: () => historyGoBack(returnUrl),
            },
          ]}
          title={productFormValues.product.name}
          titleMetadata={
            productTriggerLevel === 'OFF' ? <Badge>Off</Badge> : undefined
          }
          thumbnail={
            <Thumbnail
              alt=""
              size="medium"
              source={productFormValues?.product.thumbnail!!}
            />
          }
          primaryAction={saveButtonContents}
        >
          {importSuccessfull && (
            <Toast
              content={t('premintedNFTs.toasts.success')}
              onDismiss={() => setImportSuccessful(false)}
            />
          )}
          {dropTypeMessage && (
            <Toast
              content={dropTypeMessage}
              onDismiss={() => setDropTypeMessage(undefined)}
            />
          )}
          {defaultType === 'nft' && (
            <GlobalBanners displayedBanners={[Banners.NFT_STATUS]} />
          )}
          {isPrint && (
            <Banner>
              <Stack vertical spacing="tight">
                <Stack spacing="extraTight">
                  <Heading>{t('productForm.print.title1')}</Heading>
                  <Heading>
                    <Link
                      onClick={() => {
                        history.push(`/prints/edit/${productFormValues.print}`);
                      }}
                    >
                      {t('productForm.print.title2')}
                    </Link>
                  </Heading>
                  <Heading>{t('productForm.print.title3')}</Heading>
                </Stack>
                <p>{t('productForm.print.subtitle')}</p>
              </Stack>
            </Banner>
          )}
          {isPredefinedDrop && (
            <Banner>
              <p>
                This is a predefined drop, so the image and title will only be
                used as placeholders and will not affect the generated NFTs.
              </p>
            </Banner>
          )}
          <form>
            <Layout>
              {bannerInfo && (
                <Layout.Section>
                  <Banner
                    title={bannerInfo.title}
                    status={bannerInfo.status}
                    action={
                      bannerInfo.action !== undefined
                        ? {
                            content: bannerInfo.action.text,
                            url: bannerInfo.action.url,
                          }
                        : undefined
                    }
                    onDismiss={() => setBannerInfo(null)}
                  >
                    {bannerInfo.message}
                  </Banner>
                </Layout.Section>
              )}
              {productTriggerLevel === 'NFT' && giftingModeWarningBanner()}{' '}
              <MetafieldErrorBanner
                blockchain={blockchain}
                artistRelationships={relatedArtistsData.relatedArtist}
              />
              <Layout.Section>
                <>
                  <CardElement>
                    <TextInput
                      name={'title'}
                      customOnChange={() => methods.clearErrors('title')}
                      label={'Title'}
                      placeholder={metafields?.title?.inheritedValue?.[0]}
                      error={methods.formState.errors.title?.message}
                      // We do allow changing the title for Predefined Drops as this will be used for placeholder text
                      // in emails and on customer dashboard VER-7796
                      disabled={isExistingNFT}
                    />
                    <SelectInput
                      name={'artist_id'}
                      label={'Creator'}
                      validator={(value) => {
                        const relationship =
                          relatedArtistsData.relatedArtist.find(
                            (artist) => artist.id === value
                          );
                        // It's only not authorized if the chain is mainnet. We allow PENDING on testnet.
                        if (
                          relationship?.status === 'PENDING' &&
                          blockchain &&
                          isMainnet(blockchain)
                        ) {
                          return t(
                            'errors.metafields.general.relationship_not_authorized'
                          );
                        } else {
                          return true;
                        }
                      }}
                      options={artistOptions(relatedArtistsData, metafields)}
                      noDefault
                      customOnChange={() => methods.clearErrors('artist_id')}
                      error={
                        artistError ? (
                          <Stack spacing={'extraTight'}>
                            <span>
                              {' '}
                              {artistError}{' '}
                              {artistError ===
                              t('errors.metafields.general.required') ? (
                                <>
                                  {t(
                                    'productsSection.defaultCreatorHintPrefix'
                                  )}
                                  <Link
                                    onClick={() => history.push('/settings')}
                                  >
                                    {t(
                                      'productsSection.defaultCreatorHintLink'
                                    )}
                                  </Link>
                                </>
                              ) : null}
                            </span>
                            {currentRelationship?.type === 'SELF' && (
                              <Button
                                plain
                                destructive
                                monochrome
                                external
                                onClick={() =>
                                  openWindowOnVerisartDotCom(
                                    '/artists?verifyNow=true'
                                  )
                                }
                              >
                                {t('creatorsSection.completeSetup')}
                              </Button>
                            )}
                          </Stack>
                        ) : undefined
                      }
                    />
                    <TextInput
                      customOnChange={() => methods.clearErrors('description')}
                      name={'description'}
                      label={'Description'}
                      placeholder={metafields?.description?.inheritedValue?.[0]}
                      textbox={4}
                      error={methods.formState.errors.description?.message}
                      disabled={lockProductForm}
                    />
                    <Stack>
                      {!isNft && (
                        <Stack.Item fill>
                          <SelectInput
                            disabled={isPrint}
                            customOnChange={() =>
                              methods.clearErrors('object_type')
                            }
                            name="object_type"
                            label="Object Type"
                            placeholder={
                              metafields?.object_type?.inheritedValue?.[0]
                            }
                            options={ObjectTypeOptions.map(function (
                              objectTypeOption
                            ) {
                              return {
                                value: objectTypeOption.object_type,
                                label: objectTypeOption.label,
                              };
                            })}
                            error={
                              methods.formState.errors.object_type?.message
                            }
                          />
                        </Stack.Item>
                      )}
                      <Stack.Item>
                        <TextInput
                          name="production_year"
                          label="Production Year"
                          placeholder={
                            metafields?.production_year?.inheritedValue?.[0]
                          }
                          valueOverride={
                            isPrint ? currentYear.toString() : undefined
                          }
                          error={
                            methods.formState.errors.production_year?.message
                          }
                          customOnChange={() =>
                            methods.clearErrors('production_year')
                          }
                          disabled={isPrint}
                          validator={(value) => {
                            const yearAsInt = parseInt(value);
                            if (
                              value?.length === 0 ||
                              value === '0' ||
                              value == null ||
                              (!Number.isNaN(yearAsInt) &&
                                yearAsInt >= 1000 &&
                                yearAsInt <= currentYear)
                            ) {
                              return true;
                            }
                            return Number.isNaN(yearAsInt)
                              ? t('errors.metafields.general.not_a_number')
                              : t('errors.metafields.production_year.invalid');
                          }}
                        />
                      </Stack.Item>
                    </Stack>
                    {!isPrint && (
                      <AssetRenderer
                        title={t('productForm.assetRendererTitle')}
                        // We do allow changing the image for Predefined Drops as this will be used for placeholder text
                        // in emails and on customer dashboard VER-7796
                        disabled={isExistingNFT}
                        asset={mainAsset}
                        inherited={mainAssetInherited}
                        changeAsset={{
                          uploadFile: async (file: File) => {
                            setImageUploadError(null);
                            const uploadedURL = await uploadProductFile(
                              productFormValues.product.id,
                              'MAIN_ASSET',
                              file
                            );
                            app.showToast({
                              message: t(
                                'productForm.fileUploadedSuccessMessage'
                              ),
                              duration: toastDurationMs,
                            });
                            setMainAsset(uploadedURL);
                            methods.setValue('image_url', uploadedURL);
                          },
                          fileTypes: isNft
                            ? 'nft_assets'
                            : 'coa_presentational',
                          setError: setImageUploadError,
                        }}
                      />
                    )}
                    {imageUploadError && (
                      <TextStyle variation="negative">
                        {imageUploadError}
                      </TextStyle>
                    )}
                    {isNft &&
                      mainAsset !== undefined &&
                      !isImageFile(mainAsset) && (
                        <>
                          <AssetRenderer
                            title={'Display image'}
                            asset={displayAsset}
                            inherited={false}
                            disabled={lockProductForm}
                            changeAsset={{
                              uploadFile: async (file: File) => {
                                setDisplayImageError(null);
                                const uploadedURL = await uploadProductFile(
                                  productFormValues.product.id,
                                  'DISPLAY_IMAGE',
                                  file
                                );
                                app.showToast({
                                  message: t(
                                    'productForm.fileUploadedSuccessMessage'
                                  ),
                                  duration: toastDurationMs,
                                });
                                setDisplayAsset(uploadedURL);
                                methods.setValue(
                                  'display_image_url',
                                  uploadedURL
                                );
                              },
                              fileTypes: 'nft_images',
                              setError: setDisplayImageError,
                            }}
                          />
                        </>
                      )}
                    {displayImageError && (
                      <TextStyle variation="negative">
                        {displayImageError}
                      </TextStyle>
                    )}
                    {!isNft && !isPrint && (
                      <FormLayout>
                        <FormLayout.Group condensed>
                          <TextInput
                            name="width"
                            label="Width"
                            placeholder={metafields?.width?.inheritedValue?.[0]}
                            error={methods.formState.errors.width?.message}
                            customOnChange={() => methods.clearErrors('width')}
                          />
                          <TextInput
                            name="height"
                            label="Height"
                            placeholder={
                              metafields?.height?.inheritedValue?.[0]
                            }
                            error={methods.formState.errors.height?.message}
                            customOnChange={() => methods.clearErrors('height')}
                          />
                          <TextInput
                            name="depth"
                            label="Depth"
                            placeholder={metafields?.depth?.inheritedValue?.[0]}
                            error={methods.formState.errors.depth?.message}
                            customOnChange={() => methods.clearErrors('depth')}
                          />
                          <SelectInput
                            name="dimension_unit"
                            label="Unit"
                            options={
                              !NonPhysicalObjects.includes(
                                objectType as ObjectType
                              )
                                ? [
                                    { value: 'INCH', label: 'inch' },
                                    { value: 'CM', label: 'cm' },
                                  ]
                                : [{ value: 'PIXEL', label: 'Pixels' }]
                            }
                            placeholder={
                              metafields?.dimension_unit?.inheritedValue?.[0]
                            }
                            error={
                              methods.formState.errors.dimension_unit?.message
                            }
                            customOnChange={() =>
                              methods.clearErrors('dimension_unit')
                            }
                          />
                        </FormLayout.Group>
                      </FormLayout>
                    )}
                  </CardElement>
                  {isBundle && (
                    <CardElement title="NFT">
                      <TextInput
                        name={'nft_title'}
                        customOnChange={() => methods.clearErrors('nft_title')}
                        label={'Title'}
                        placeholder={
                          metafields?.nft_title?.inheritedValue?.[0] ??
                          metafields?.title?.value?.[0] ??
                          metafields?.title?.inheritedValue?.[0]
                        }
                        error={methods.formState.errors.nft_title?.message}
                        // We do allow changing the title for Predefined Drops as this will be used for placeholder text
                        // in emails and on customer dashboard VER-7796
                        disabled={isExistingNFT}
                      />
                      <TextInput
                        customOnChange={() =>
                          methods.clearErrors('nft_description')
                        }
                        name={'nft_description'}
                        label={'Description'}
                        placeholder={
                          metafields?.nft_description?.inheritedValue?.[0] ??
                          metafields?.description?.value?.[0] ??
                          metafields?.description?.inheritedValue?.[0]
                        }
                        textbox={4}
                        error={
                          methods.formState.errors.nft_description?.message
                        }
                        disabled={lockProductForm}
                      />
                      <AssetRenderer
                        title={t('productForm.assetRendererTitle')}
                        // We do allow changing the image for Predefined Drops as this will be used for placeholder text
                        // in emails and on customer dashboard VER-7796
                        disabled={isExistingNFT}
                        asset={nftImage}
                        inherited={false}
                        changeAsset={{
                          uploadFile: async (file: File) => {
                            setNftImageUploadError(null);
                            const uploadedURL = await uploadProductFile(
                              productFormValues.product.id,
                              'NFT_ASSET',
                              file
                            );
                            app.showToast({
                              message: t(
                                'productForm.fileUploadedSuccessMessage'
                              ),
                              duration: toastDurationMs,
                            });
                            setNftImage(uploadedURL);
                            methods.setValue('nft_image_url', uploadedURL);
                          },
                          fileTypes: 'nft_assets',
                          setError: setNftImageUploadError,
                        }}
                      />
                      {nftImageUploadError && (
                        <TextStyle variation="negative">
                          {nftImageUploadError}
                        </TextStyle>
                      )}
                      {nftImage !== undefined && !isImageFile(nftImage) && (
                        <AssetRenderer
                          title={'Display image'}
                          asset={nftDisplayAsset}
                          inherited={false}
                          disabled={lockProductForm}
                          changeAsset={{
                            uploadFile: async (file: File) => {
                              setNftDisplayImageError(null);
                              const uploadedURL = await uploadProductFile(
                                productFormValues.product.id,
                                'NFT_DISPLAY_IMAGE',
                                file
                              );
                              app.showToast({
                                message: t(
                                  'productForm.fileUploadedSuccessMessage'
                                ),
                                duration: toastDurationMs,
                              });
                              setNftDisplayAsset(uploadedURL);
                              methods.setValue(
                                'nft_display_image_url',
                                uploadedURL
                              );
                            },
                            fileTypes: 'nft_images',
                            setError: setNftDisplayImageError,
                          }}
                        />
                      )}
                      {nftDisplayImageError && (
                        <TextStyle variation="negative">
                          {nftDisplayImageError}
                        </TextStyle>
                      )}
                    </CardElement>
                  )}
                  <CardElement
                    title="Edition"
                    helpText={
                      isNft ? (
                        <Link
                          external
                          url="https://help.verisart.com/en/articles/6176930-configuring-nfts-in-shopify#h_980edcafed"
                        >
                          {t(
                            'productsSection.metaDataForms.basic.edition.helpCenterText'
                          )}
                        </Link>
                      ) : null
                    }
                  >
                    <Stack>
                      {editionLocked && (
                        <Stack.Item>
                          <EditionLockedBanner
                            isNFT={isNft}
                            autoNumberEditionNext={
                              productFormValues.autoNumberEditionNext
                            }
                            setResetEditionModal={setResetEditionModal}
                          />
                        </Stack.Item>
                      )}
                      <Stack.Item>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'center',
                            justifyContent: 'center',
                            height: '100%',
                          }}
                        >
                          <span>
                            {t(
                              'productsSection.metaDataForms.basic.edition.subheading'
                            )}
                          </span>
                        </div>
                      </Stack.Item>
                      <Stack.Item fill>
                        <div
                          style={{
                            display: 'flex',
                            gap: '2rem',
                          }}
                        >
                          <Controller
                            name="is_this_edition"
                            control={methods.control}
                            render={({ field: { value, onChange } }) => (
                              <>
                                <RadioButton
                                  checked={value === 'yes'}
                                  onChange={(item) => {
                                    onChange('yes');
                                  }}
                                  id="yes"
                                  value="yes"
                                  label="Yes"
                                  disabled={lockProductForm || editionLocked}
                                />
                                <RadioButton
                                  checked={value === 'no'}
                                  onChange={(item) => onChange('no')}
                                  id="no"
                                  value="no"
                                  label="No"
                                  disabled={lockProductForm || editionLocked}
                                />
                              </>
                            )}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                    {showEdition && (
                      <>
                        <SelectInput
                          name="edition_type"
                          label="Edition Type"
                          options={editionTypes(
                            capabilities!!.capabilities.indexOf(
                              'certificates.editions.bulkCreate'
                            ) !== -1,
                            isNft
                          )
                            .filter((item) => {
                              return (
                                !item.disabled ||
                                !isNft ||
                                item.value === originalEditionType
                              );
                            })
                            .map(function (item) {
                              return {
                                value: item.value as string,
                                label: item.label,
                                disabled: item.disabled,
                              };
                            })}
                          error={methods.formState.errors.edition_type?.message}
                          customOnChange={() =>
                            methods.clearErrors('edition_type')
                          }
                          disabled={editionLocked}
                        />
                        {editionTypeToShow === 'multiple_auto' && (
                          <TextInput
                            name="auto_number_editions"
                            label="Numbering Starts from"
                            error={
                              methods.formState.errors.auto_number_editions
                                ?.message
                            }
                            customOnChange={() =>
                              methods.clearErrors('auto_number_editions')
                            }
                            disabled={editionLocked}
                          />
                        )}
                        {(editionTypeToShow === 'single_edition' ||
                          editionTypeToShow === 'single_ap') && (
                          <Stack>
                            <Stack.Item fill>
                              <TextInput
                                name={
                                  editionTypeToShow === 'single_edition'
                                    ? 'edition_number'
                                    : 'ap_number'
                                }
                                label={
                                  editionTypeToShow === 'single_edition'
                                    ? 'Edition Number'
                                    : 'Artist Proof number'
                                }
                                error={
                                  editionTypeToShow === 'single_edition'
                                    ? methods.formState.errors.edition_number
                                        ?.message
                                    : methods.formState.errors.ap_number
                                        ?.message
                                }
                                customOnChange={() =>
                                  methods.clearErrors(
                                    editionTypeToShow === 'single_edition'
                                      ? 'edition_number'
                                      : 'ap_number'
                                  )
                                }
                                disabled={editionLocked}
                              />
                            </Stack.Item>
                            <Stack.Item fill>
                              <TextInput
                                name={
                                  editionTypeToShow === 'single_edition'
                                    ? 'edition_volume'
                                    : 'ap_volume'
                                }
                                label={
                                  editionTypeToShow === 'single_edition'
                                    ? 'Edition Volume'
                                    : 'Artist Proof volume'
                                }
                                error={
                                  editionTypeToShow === 'single_edition'
                                    ? methods.formState.errors.edition_volume
                                        ?.message
                                    : methods.formState.errors.ap_volume
                                        ?.message
                                }
                                customOnChange={() =>
                                  methods.clearErrors(
                                    editionTypeToShow === 'single_edition'
                                      ? 'edition_volume'
                                      : 'ap_volume'
                                  )
                                }
                                disabled={editionLocked}
                              />
                            </Stack.Item>
                          </Stack>
                        )}
                        {editionTypeToShow !== undefined && (
                          <TextInput
                            name={
                              editionTypeToShow !== null &&
                              ApVolumeEditionTypes.includes(editionTypeToShow)
                                ? 'ap_volume'
                                : 'edition_volume'
                            }
                            label={
                              editionTypeToShow !== null &&
                              ApVolumeEditionTypes.includes(editionTypeToShow)
                                ? 'Artist Proof Volume'
                                : 'Edition volume'
                            }
                            error={
                              editionTypeToShow !== null &&
                              ApVolumeEditionTypes.includes(editionTypeToShow)
                                ? methods.formState.errors.ap_volume?.message
                                : methods.formState.errors.edition_volume
                                    ?.message
                            }
                            customOnChange={() =>
                              methods.clearErrors(
                                editionTypeToShow !== null &&
                                  ApVolumeEditionTypes.includes(
                                    editionTypeToShow
                                  )
                                  ? 'ap_volume'
                                  : 'edition_volume'
                              )
                            }
                            disabled={editionLocked}
                          />
                        )}
                        <TextInput
                          name="edition_note"
                          label="Edition Note"
                          textbox={4}
                          placeholder={
                            metafields?.edition_note?.inheritedValue?.[0]
                          }
                          error={methods.formState.errors.edition_note?.message}
                          customOnChange={() =>
                            methods.clearErrors('edition_note')
                          }
                          disabled={editionLocked}
                        />
                      </>
                    )}
                  </CardElement>
                  <CollectorRewards
                    id={productFormValues.product.id}
                    type="PRODUCT"
                    productType={productTriggerLevel}
                    assets={collectorRewardAssets}
                    setAsset={setCollectorRewardAssets}
                    subtitle
                    readOnlyRewards={dropData?.collectorRewardFiles}
                    fileUploadSuccessMessage={t(
                      'productForm.fileUploadedSuccessMessage'
                    )}
                    uploadFile={async (file: File) => {
                      const uploadedURL = await uploadProductFile(
                        productFormValues.product.id,
                        'COLLECTOR_REWARD',
                        file
                      );
                      setCollectorRewardAssets([
                        ...collectorRewardAssets,
                        { fileURL: uploadedURL, fileLabel: file.name },
                      ]);
                      app.showToast({
                        message: t('productForm.fileUploadedSuccessMessage'),
                        duration: 5000,
                      });
                    }}
                  />
                  {!isNft && (
                    <CardElement title="Additional information">
                      <TextInput
                        name="production_location"
                        label="Production location"
                        error={
                          methods.formState.errors.production_location?.message
                        }
                        customOnChange={() =>
                          methods.clearErrors('production_location')
                        }
                      />
                      <TextInput
                        name="current_location"
                        label="Current location"
                        error={
                          methods.formState.errors.current_location?.message
                        }
                        customOnChange={() =>
                          methods.clearErrors('current_location')
                        }
                      />
                      <TextInput
                        name="inventory_number"
                        label="Inventory number"
                        error={
                          methods.formState.errors.inventory_number?.message
                        }
                        customOnChange={() =>
                          methods.clearErrors('inventory_number')
                        }
                      />
                    </CardElement>
                  )}
                  {true && (isNft || isBundle) && (
                    <CardElement title="Traits">
                      <TextInput
                        name="traits"
                        disabled={lockProductForm}
                        label={t('productsSection.traitsLabel')}
                        textbox={8}
                        placeholder="[
                                {“trait_type”: “Background”,
                                “value”: “LEAVES”},
  
                                {“trait_type”: “Eyes”,
                                “value”: “GREEN”},
  
                                {“trait_type”: “Hair”,
                                “value”: “LONG”},
  
                                {“trait_type”: “Body”,
                                “value”: “Round”},
  
                                {“trait_type”: “Teeth”,
                                “value”: “Gold”}
                                ]"
                        helpText={t('productsSection.helpText')}
                        labelAction={{
                          content: t('productsSection.labelActionContent'),
                          url: 'https://help.verisart.com/en/articles/6176930-configuring-nfts-in-shopify#h_e263e52ebd',
                        }}
                        error={methods.formState.errors.traits?.message}
                        customOnChange={() => methods.clearErrors('traits')}
                      />
                    </CardElement>
                  )}
                  {productFormValues.product.variants.length !== 0 && (
                    <VariantsLinkSection
                      variantList={productFormValues.product.variants}
                    />
                  )}
                </>
              </Layout.Section>
              <Layout.Section secondary>
                <ProductInfo
                  productTriggerLevel={productTriggerLevel}
                  drop={dropData}
                  blockchain={blockchain}
                  contract={contract}
                  contractAddress={contractMetafield}
                  tokenId={tokenIdExisting}
                  paymentSplitter={royaltySplitter}
                  existingNFTStatus={existingNFTStatus}
                />
                <CustomizationInfo />
                {!isPrint && (
                  <QRPanel accountHasPurchasedSticker={!!hasPurchasedSticker} />
                )}
                {isNft && !lockProductForm && <Tags />}
              </Layout.Section>
              <ResetEditionModal
                productId={productFormValues.product.id}
                show={resetEditionModal}
                setShow={setResetEditionModal}
              />
              {onDeactivateProduct && (
                <Layout.Section fullWidth>
                  <Modal
                    open={unlinkModalOpen}
                    onClose={() => {
                      setUnlinking(false);
                      setUnlinkModalOpen(false);
                    }}
                    title={unlinkMessage.modalTitle}
                    primaryAction={{
                      content: unlinkMessage.confirmationButton,
                      destructive: true,
                      loading: isUnlinking,
                      async onAction() {
                        setUnlinking(true);
                        await onDeactivateProduct?.();
                        app.showToast({
                          message: unlinkMessage.successMessage,
                          duration: toastDurationMs,
                        });
                        setUnlinkModalOpen(false);
                      },
                    }}
                    secondaryActions={[
                      {
                        content: t('productForm.delete.cancel'),
                        onAction() {
                          setUnlinkModalOpen(false);
                        },
                      },
                    ]}
                  >
                    <Modal.Section>
                      <TextStyle>{unlinkMessage.confirmationMessage}</TextStyle>
                    </Modal.Section>
                  </Modal>
                  <PageActions
                    primaryAction={saveButtonContents}
                    secondaryActions={[
                      {
                        disabled: isPrint,
                        content: unlinkMessage.title,
                        destructive: true,
                        outline: true,
                        async onAction() {
                          setUnlinkModalOpen(true);
                        },
                      },
                    ]}
                  />
                </Layout.Section>
              )}
            </Layout>
          </form>
        </Page>
      </FormProvider>
    </div>
  );
};

export default ProductFormShow;

export type EditionType =
  | 'single_edition'
  | 'single_ap'
  | 'multiple_unnumbered_edition'
  | 'multiple_unnumbered_ap'
  | 'multiple_auto';

export function extractArtistOption(
  artistAttributes: ArtistAttributes
): string {
  const firstName: string = artistAttributes.name.firstName;
  const lastName: string | undefined = artistAttributes.name.lastName;
  const yearOfBirth: number | undefined = artistAttributes.yearOfBirth;
  return `${firstName}${lastName ? ' ' + lastName : ''}${
    yearOfBirth ? ' (' + yearOfBirth + ')' : ''
  }`;
}

enum EditionTypeValidation {
  single_edition = 'single_edition',
  single_ap = 'single_ap',
  multiple_unnumbered_edition = 'multiple_unnumbered_edition',
  multiple_unnumbered_ap = 'multiple_unnumbered_ap',
  multiple_auto = 'multiple_auto',
}

function isEditionType(val: string): boolean {
  return val in EditionTypeValidation;
}

export type MetafieldFormValues = {
  [metafield in MetafieldsType]: string | null;
} & {
  is_this_edition: 'yes' | 'no';
  product_trigger_level: TriggerLevel;
  status: 'ACTIVE' | 'DRAFT';
};

const ApVolumeEditionTypes: EditionType[] = [
  'single_edition',
  'multiple_unnumbered_ap',
];

const editionTypes = (
  canMultipleEditions: boolean,
  isNFT: boolean
): { label: string; value: EditionType; disabled: boolean }[] => [
  {
    label: t(
      'productsSection.metaDataForms.basic.edition.editionTypeLabels.' +
        'multiple_unnumbered_edition'
    ),
    value: 'multiple_unnumbered_edition',
    disabled: !canMultipleEditions,
  },
  {
    label: t(
      'productsSection.metaDataForms.basic.edition.editionTypeLabels.' +
        'multiple_auto'
    ),
    value: 'multiple_auto',
    disabled: !canMultipleEditions,
  },
  {
    label: t(
      'productsSection.metaDataForms.basic.edition.editionTypeLabels.' +
        'single_edition'
    ),
    value: 'single_edition',
    disabled: isNFT,
  },
  {
    label: t(
      'productsSection.metaDataForms.basic.edition.editionTypeLabels.' +
        'single_ap'
    ),
    value: 'single_ap',
    disabled: isNFT,
  },
  {
    label: t(
      'productsSection.metaDataForms.basic.edition.editionTypeLabels.' +
        'multiple_unnumbered_ap'
    ),
    value: 'multiple_unnumbered_ap',
    disabled: !canMultipleEditions || isNFT,
  },
];

function artistOptions(
  relatedArtistsData: ManagedArtists,
  metafields: MetafieldToValue
): (SelectOption | SelectGroup)[] {
  const artistSelectOptionsAuthorized = relatedArtistsData.relatedArtist
    .filter((item) => item.status === 'AUTHORIZED')
    .map(function (artist) {
      return {
        label: extractArtistOption(artist.attributes),
        value: artist.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const artistSelectOptionsPending = relatedArtistsData.relatedArtist
    .filter((item) => item.status === 'PENDING')
    .map(function (artist) {
      return {
        label: extractArtistOption(artist.attributes),
        value: artist.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const inheritedArtistId = metafields?.artist_id?.inheritedValue?.[0];
  const inheritedArtist = inheritedArtistId
    ? relatedArtistsData.relatedArtist.find((it) => it.id === inheritedArtistId)
    : null;
  return [
    {
      value: '',
      label: inheritedArtist
        ? p(
            'productsSection.usingDefaultCreator',
            extractArtistOption(inheritedArtist.attributes)
          )
        : 'Select',
    },
    {
      title: 'Authorized',
      options: artistSelectOptionsAuthorized,
    },
    {
      title: 'Pending',
      options: artistSelectOptionsPending,
    },
  ];
}
