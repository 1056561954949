import { Card, Icon, Stack, TextStyle } from '@shopify/polaris';
import TextInput from 'components/forms/TextInput';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { PrintFormValues } from './PrintForm';
import { AlertMinor } from '@shopify/polaris-icons';
import useShop from 'hooks/useShop';
import { PlanType } from 'utils/plans';

interface ProfitCalculatorProps {
  methods: UseFormReturn<PrintFormValues>;
}

const ProfitCalculator: React.FC<ProfitCalculatorProps> = ({ methods }) => {
  const printPrice = methods.watch('price');
  const { data } = useShop();
  const feePercentage =
    data?.shop.planType === PlanType.PAY_AS_YOU_GO ? 0.1 : 0.07;
  return (
    <Card title="Profit calculator">
      <Stack distribution="fillEvenly">
        <Card.Section>
          <Stack vertical spacing="extraLoose">
            <Stack vertical spacing="tight">
              <TextStyle variation="strong">Price</TextStyle>
              <div style={{ width: '230px' }}>
                <Controller
                  name="price"
                  control={methods.control}
                  rules={{
                    required: 'Product price is required',
                    pattern: {
                      value: /^[0-9]*\.?[0-9]{0,2}$/,
                      message: 'Price must be a number',
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <div style={{ width: '80px' }}>
                        <TextInput
                          {...field}
                          prefix="$"
                          required={'Product price is required'}
                          label=""
                          errorOverride={true}
                        />
                      </div>
                      {methods.formState.errors?.price && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#D72C0D',
                            marginTop: '10px',
                            justifyContent: 'flex-start',
                            float: 'left',
                          }}
                        >
                          <Icon source={AlertMinor} color="critical" />
                          <span style={{ marginLeft: '5px' }}>
                            {methods.formState.errors?.price?.message}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </Stack>
          </Stack>
        </Card.Section>
        <Card.Section>
          <Stack vertical>
            <Stack vertical spacing="tight">
              <TextStyle variation="strong">Breakdown</TextStyle>
              <Stack vertical spacing="extraTight">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>
                    <TextStyle variation="subdued">Print cost</TextStyle>{' '}
                  </p>
                  <p style={{ color: 'gray', marginLeft: 'auto' }}>$25.00</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>
                    <TextStyle variation="subdued">NFC tag</TextStyle>{' '}
                  </p>
                  <p style={{ color: 'gray', marginLeft: 'auto' }}>$3.50</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>
                    {data?.shop.planType === PlanType.PAY_AS_YOU_GO ? (
                      <TextStyle variation="subdued">10% fee</TextStyle>
                    ) : (
                      <TextStyle variation="subdued">7% fee</TextStyle>
                    )}
                  </p>
                  <p style={{ color: 'gray', marginLeft: 'auto' }}>
                    {printPrice
                      ? `$${(printPrice * feePercentage).toFixed(2)}`
                      : '$0.25'}
                  </p>
                </div>
              </Stack>
              <Stack vertical spacing="tight">
                <TextStyle variation="strong">Profit</TextStyle>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>
                    <span style={{ color: 'black' }}>Total after costs</span>
                  </p>
                  <p style={{ color: 'black', marginLeft: 'auto' }}>
                    {printPrice
                      ? `$${(
                          printPrice -
                          25 -
                          3.5 -
                          printPrice * feePercentage
                        ).toFixed(2)}`
                      : '$0.00'}
                  </p>
                </div>
              </Stack>
            </Stack>
          </Stack>
        </Card.Section>
      </Stack>
    </Card>
  );
};

export default ProfitCalculator;
