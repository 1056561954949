import { Banner } from '@shopify/polaris';
import React from 'react';
import t from 'lib/translation';
import { BalanceName, useQuota } from 'hooks/useQuota';
import useSession, { isAccountSessionLoaded } from '../../hooks/useSession';
import { useHistory } from 'react-router-dom';

const ConnectedQuotaExceededBanner: React.FC = () => {
  const history = useHistory();
  const accountSessionLoaded = useSession(isAccountSessionLoaded);
  const { data: quotaArtistRelationships } = useQuota(
    BalanceName.ArtistRelationships,
    { enabled: accountSessionLoaded }
  );
  const { data: quotaObject } = useQuota(BalanceName.Object, {
    enabled: accountSessionLoaded,
  });
  const { data: quotaCertificateCustomization } = useQuota(
    BalanceName.CertificateCustomization,
    { enabled: accountSessionLoaded }
  );
  const { data: quotaNFTCustomContract } = useQuota(
    BalanceName.NFTCustomContract,
    { enabled: accountSessionLoaded }
  );
  const showQuotaExceededBanner =
    (quotaArtistRelationships?.exceeded ?? false) ||
    (quotaObject?.exceeded ?? false) ||
    (quotaCertificateCustomization?.exceeded ?? false) ||
    (quotaNFTCustomContract?.exceeded ?? false);

  return showQuotaExceededBanner ? (
    <div>
      <Banner
        status="warning"
        action={{
          content: t('banners.quotaBanner.actionContent'),
          onAction: () => history.push('/plan'),
        }}
        title={t('banners.quotaBanner.title')}
      >
        {t('banners.quotaBanner.content')}
      </Banner>
    </div>
  ) : null;
};

export default ConnectedQuotaExceededBanner;
