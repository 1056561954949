import * as React from 'react';
import { TextStyle, Card, Button, Heading } from '@shopify/polaris';
import styles from './EmptyStatePage.module.scss';
import { useHistory } from 'react-router';

export interface EmptyStatePageProps {
  linkAction?: () => void;
  heading: string;
  body?: string;
  linkText?: string;
  upgradeRequired?: boolean;
}

const EmptyStatePage: React.FC<EmptyStatePageProps> = ({
  linkAction,
  heading,
  body,
  linkText,
  upgradeRequired,
}) => {
  const history = useHistory();
  return (
    <Card>
      <div className={`${styles.container}`}>
        <Heading>{heading}</Heading>
        {body && <TextStyle variation="subdued">{body}</TextStyle>}
        {linkText && linkAction && (
          <Button
            primary
            onClick={() => {
              upgradeRequired ? history.push('/plan') : linkAction();
            }}
          >
            {upgradeRequired ? 'Upgrade plan' : linkText}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default EmptyStatePage;
